<template>
<!--  <div v-if="isLoading">-->
<!--    <div class="d-flex flex-row align-items-center mt-5">-->
<!--      <div class="mx-auto my-auto">-->
<!--        <b-spinner class="brand-primary"></b-spinner>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div id="Podcast">
    <section class="header-container">
      <div class="content-container mb-5 header-back-button-container">
        <button class="btn-clear" @click="goBack()">
          <i class="fas fa-5x fa-chevron-left header-back"></i>
        </button>
      </div>
      <div
        class="
          flex-row
          d-flex
          justify-content-around
          align-items-center
          mb-5
          h-100
          content-container
        "
      >
        <div class="header-text">
          <h1 class="header-title brand-text">
            <strong>{{ podcast.title }}</strong>
          </h1>
          <div class="row d-lg-none">
            <img
              class="header-small-image"
              :src="podcast.image_url ? podcast.image_url : '/img/headphones.svg'"
              alt="Headphones icon"
              @error="imageUrlAlt"
            />
          </div>
          <div class="podcast-metadata d-flex flex-row flex-wrap pb-3">
            <div class="d-flex metadata-item brand-text align-items-center">
              <i class="fas fa-podcast pr-2"></i> {{ podcast.episodes }}
              {{ componentText.episodes }}
            </div>
<!--            <div-->
<!--              class="d-flex metadata-item brand-text align-items-center"-->
<!--              v-if="podcast.listeners !== 0"-->
<!--            >-->
<!--              <i class="fas fa-headphones pr-2"></i>-->
<!--              {{ podcast.listeners }} {{ componentText.listeners }}-->
<!--            </div>-->
<!--            <div-->
<!--              class="d-flex metadata-item brand-text align-items-center"-->
<!--              v-if="podcast.subscribers !== 0"-->
<!--            >-->
<!--              <i class="fas fa-heart pr-2"></i> {{ podcast.subscribers }}-->
<!--              {{ componentText.subscribers }}-->
<!--            </div>-->
          </div>
          <p class="header-content brand-text" v-html="podcast.description"></p>
          <div class="header-buttons row">
            <button
              :class="[
                podcast.subscribed ? 'cta-button' : 'btn-outline',
                'btn px-5 py-3 mr-5 mt-5',
              ]"
              @click="subscribe()"
              v-if="!$store.getters.user.access_code_user"
            >
              <span v-if="podcast.subscribed">
                {{ componentText.subscribed }} </span
              ><span v-else>{{ componentText.subscribe }}</span
              ><i class="fas fa-heart btn-icon"></i>
            </button>
            <button
              class="btn cta-button px-5 py-3 mt-5"
              @click="setPodcastQueue(queue, 0)"
            >
              {{ componentText.listen_now }}<i class="fas fa-play btn-icon"></i>
            </button>
          </div>
        </div>
        <div class="header-image d-none d-lg-block">
          <img class="img-fluid" :src="podcast.image_url ? podcast.image_url : '/img/headphones.svg'" alt="play icon" @error="imageUrlAlt"/>
        </div>
      </div>
    </section>
    <section class="episodes-container">
      <div class="content-container">
        <h2 class="sorter" @click="toggleSortOrder">
          Reverse
          <i
            :class="[
              sortDescending
                ? 'fa-long-arrow-alt-down'
                : 'fa-long-arrow-alt-up',
              'fas',
            ]"
          ></i>
        </h2>
        <div class="episode-container row mx-0">
          <div class="col-sm-12">
            <div
              class="episode"
              v-for="(episode, i) in sortedEpisodes"
              :key="i"
              :ref="'pc-'+episode.id"
              style="position: relative;"
            >
              <i :id="'myList-'+episode.id" v-if="isVersionTwo" class="fa-solid myListBtn mr-4" :class="listItems.includes(episode.id) ? 'fa-circle-check plus':'fa-circle-plus plus'" @click="watchLater(episode.id)"></i>
              <b-tooltip :target="'myList-'+episode.id" triggers="hover" placement="top" custom-class="mylist-tooltip">
                {{listItems.includes(episode.id) ? 'Remove from My List' : 'Add to My List'}}
              </b-tooltip>
              <div class="d-flex flex-row">
                <div class="episode-image-container d-none d-md-block">
                  <div class="d-flex justify-content-center">
                    <img
                      class="episode-image"
                      :src="podcast.image_url ? podcast.image_url : '/img/headphones.svg'"
                      alt="Headphones icon"
                      @error="imageUrlAlt"
                    />
                  </div>
                </div>
                <div class="episode-text-container">
                  <h2 class="episode-title brand-text">
                    {{ episode.title }}
                  </h2>
                  <div
                    class="
                      episode-image-container
                      d-flex
                      justify-content-center
                      d-md-none
                      pb-5
                    "
                  >
                    <img
                      class="episode-image"
                      :src="podcast.image_url ? podcast.image_url : '/img/headphones.svg'"
                      alt="Headphones icon"
                      @error="imageUrlAlt"
                    />
                  </div>
                  <div class="episode-metadata d-flex flex-row pb-3">
                    <div class="metadata-item brand-text">
                      <i class="fas fa-calendar pr-2"></i
                      >{{ getFormatedDate(episode.pub_date, "DD/MM/YY") }}
                    </div>
                    <div class="metadata-item brand-text">
                      <i class="fas fa-clock pr-2"></i
                      >{{ formatTime(episode.duration) }}
                    </div>
<!--                    <div class="metadata-item brand-text">-->
<!--                      <i class="fas fa-heart pr-2"></i>{{ episode.listeners }}-->
<!--                      {{ componentText.listeners }}-->
<!--                    </div>-->
                  </div>
                  <div class="episode-blurb brand-text">
                    <read-more
                      :text="episode.description"
                      :limit="500"
                    ></read-more>
                  </div>
                  <div class="episode-play-button row pt-5 d-lg-none">
                    <button
                      class="btn cta-button px-5 py-3"
                      @click="setPodcastQueue(queue, i)"
                    >
                      <span
                        >{{ componentText.listen_now }}
                        <i class="fas fa-play btn-icon"></i
                      ></span>
                    </button>
                  </div>
                </div>
                <div
                  class="
                    episode-button-container
                    align-self-end
                    d-none d-lg-block
                  "
                >
                  <div
                    class="episode-play-button row center h100 align-self-end"
                  >
                    <button
                      class="btn cta-button px-5 py-3 mx-auto"
                      @click="setPodcastQueue(queue, i)"
                    >
                      <span
                        >Listen Now<i class="fas fa-play btn-icon"></i
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import ReadMore from "@/components/ReadMore.vue";

export default {
  name: "Podcast",
  components: {
    ReadMore,
  },
  data() {
    return {
      isLoading: true,
      sortDescending: true,
      name: "",
      podcast: {},
      episodes: [],
      firstInitialised: true
    };
  },
  mounted() {
    this.getPodcasts();
  },
  methods: {
    async watchLater(id) {
      if(!this.listItems.includes(id)) {
        await this.$store.dispatch('addMyListItem',({media_type: 'podcast', product_id: 18, content_id: id}));
      } else {
        const myListRecord = this.$store.getters.myList.filter(item => item.product_id === 18 && item.content_id === id);
        await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/img/headphones.svg"
    },
    secondsToString(time) {
      var sec_num = parseInt(time, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if ((hours = "00")) {
        return minutes + ":" + seconds;
      } else {
        return hours + ":" + minutes + ":" + seconds;
      }
    },
    formatTime(time) {
      if(!time) return "00:00"
      if (time.includes(":")) {
        time = time.replace(/^0+/, "");
        if (time.charAt(0) === ":") {
          time = time.substring(1);
        }
        return time;
      } else {
        return this.secondsToString(parseInt(time));
      }
    },
    goBack() {
        if(this.$route.query.back === 'home') {
            this.$router.push("/").catch((err) => {
            })
        } else {
            this.$router.push("/sound-space").catch((err) => {});
        }
    },
    toggleSortOrder() {
      this.sortDescending = !this.sortDescending;
    },
    async getPodcasts() {
      const payload = {
        user_id: this.$store.getters.user_id,
        podcast_id: this.$route.params.id,
        episode_id: this.$route.query.play
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/podcasts/get-podcast",
        payload,
        config.options
      );
      if (res.data.success) {
        this.podcast = res.data.podcast;
        this.episodes = res.data.episodes;
        this.isLoading = false;
        if(this.$route.query.play && this.firstInitialised) {
          this.playSelectedEpisodeFromInit();
        };
        this.firstInitialised = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    playSelectedEpisodeFromInit() {
      const i = this.episodes.findIndex(episode => Number.parseInt(episode.id) === Number.parseInt(this.$route.query.play));
      this.setPodcastQueue(this.queue,i);
    },
    setPodcastQueue(queue, position) {
      this.countView(this.sortedEpisodes[position].id);
      let queueAndPosition = { queue: queue, position: position };
      this.$store.dispatch("setPodcastQueue", queueAndPosition);
    },
    async countView(id) {
      const payload = {
        user_id: this.$store.getters.user_id,
        episode_id: id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/podcasts/listen-episode",
        payload,
        config.options
      );
      if (res.data.success) {
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async subscribe() {
      const payload = {
        user_id: this.$store.getters.user_id,
        podcast_id: this.$route.params.id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/podcasts/subscribe",
        payload,
        config.options
      );
      if (res.data.success) {
        if (res.data.msg === "Successfully subscribed") {
          this.podcast.subscribers++;
          this.podcast.subscribed = true;
        } else {
          this.podcast.subscribers--;
          this.podcast.subscribed = false;
        }
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
  },
  computed: {
    product_id () {
      return 18;
    },
    listItems() {
      const items = this.$store.getters.myList.filter(item => item.product_id === this.product_id).map(item => item.content_id);
      if(items.length) return items;
      return [];
    },
    isVersionTwo() {
      return this.$store.getters.navType === 'versionTwo';
    },
    componentText() {
      return this.getText.loggedIn.podcasts;
    },
    sortedEpisodes() {
      let episodes = this.episodes;
      let sortedEpisodes;
      if (this.sortDescending) {
        sortedEpisodes = episodes.sort(function (a, b) {
          return new Date(b.pub_date) - new Date(a.pub_date);
        });
      } else {
        sortedEpisodes = episodes.sort(function (a, b) {
          return new Date(a.pub_date) - new Date(b.pub_date);
        });
      }
      return sortedEpisodes;
    },
    queue() {
      let queue = [];
      this.sortedEpisodes.forEach(function (episode) {
        queue.push({ title: episode.title, source: episode.media_url });
      });
      return queue;
    },
  },
};
</script>
<style lang="less">
#Podcast {
  .header-container {
    background-color: #f9f9f9;
    height: auto;
    padding: 50px 30px;
    .header-back-button-container {
      .header-back {
        color: #707070;
      }
    }
    .header-text {
      max-width: 700px;
      .header-title {
        font-size: 4.6rem;
        padding-bottom: 20px;
        font-family: "FilsonProBold", sans-serif;
      }
      .header-content {
        font-size: 1.6rem;
      }
      .header-small-image {
        height: auto;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          max-height: 500px;
        }
      }
    }
    .header-image {
      height: auto;
      max-width: 400px;
    }
    .header-buttons {
      margin-top: 35px;
      box-sizing: border-box;
      margin-left: 0;
    }
  }
  .episodes-container {
    background-color: #e0e0e0;
    height: auto;
    padding: 35px 35px 0 35px;

    .episode-container {
      .episode {
        padding: 40px;
        margin: 20px 0;
        background-color: white;
        border-radius: 24px;
        overflow: hidden;
        @media only screen and (max-width: 992px) {
          padding: 20px;
        }
        .episode-text-container {
          flex-grow: 1;
          padding: 0 20px;
          .episode-title {
            font-size: 2.8rem;
            font-weight: bold;
            text-align: left;
            font-family: "FilsonProBold", sans-serif;
          }
          .episode-metadata {
            font-size: 1.6rem;
            .metadata-item {
              margin-right: 10px;
            }
          }
          .episode-blurb {
            font-size: 1.6rem;
            padding-top: 20px;
            text-align: left;
          }
        }
        .episode-button-container {
          .episode-play-button {
            .btn {
              font-size: 1.6rem;
              min-width: 200px;
              font-family: "FilsonProBold", sans-serif;
            }
          }
        }
        .episode-image-container {
          max-width: 75%;
          margin: 0 auto;
          @media only screen and (min-width: 768px) {
            max-width: 250px;
            min-width: 250px;
          }
          .episode-image {
            max-width: 100%;
            height: auto;
            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              max-height: 300px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .podcast-metadata {
    font-size: 1.6rem;
    .metadata-item {
      margin-right: 15px;
    }
    .icon-image {
      max-height: 2rem;
      max-width: 2rem;
      margin-right: 10px;
    }
  }
  .btn-icon {
    display: inline-block;
    margin-left: 15px;
  }
  .sorter {
    cursor: pointer;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: underline;
    font-family: "FilsonProBold", sans-serif;
    color: #707070;
    margin-left: 30px;
  }
  .btn-clear {
    border: none;
    background-color: transparent;
  }
  .btn-clear:focus {
    border: none;
    outline: none !important;
  }
  @media screen and(max-width: 767px) {
    .episode-text-container {
      padding: 3px !important;
      width: 100%;
      word-wrap: break-word;
    }
    .header-text, .episode-title {
      width: 100%;
    }
    .header-content {
      width: 100%;
      text-wrap: normal;
      word-wrap: break-word;
    }
  }
  .myListBtn {
    position: absolute;
    top: 40px;
    right: 20px;
    font-size: 3rem;
    cursor: pointer;
  }
}
</style>
