<template>
  <div id="SideBar" :class="{show: show}">
    <div class="top">
      <div class="option" @click="selectedOption = 0" :class="{selected: selectedOption === 0}">{{showReminders ? 'Unread' : 'Upcoming'}}</div>
      <div class="option" @click="selectedOption = 1" :class="{selected: selectedOption === 1}">{{showReminders ? 'Read' : 'Past'}}</div>
    </div>
    <div v-if="showAppointments">
      <div v-if="appointmentsToDisplay.length === 0" class="text-white noAppts">You have no appointments to view</div>
      <AppointmentCard v-for="appt in appointmentsToDisplay" :item="appt" :upcoming="selectedOption === 0"/>
    </div>
    <div v-else>
      <div v-if="notifications_to_view.length === 0" class="text-white noAppts">There are no notifications to view</div>
      <Notification v-for="notification in notifications_to_view" :notification="notification"/>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import AppointmentCard from "./AppointmentCard.vue";
import EventBus from "../helpers/eventbus";
import Notification from "./Notification.vue";
export default {
  components: {Notification, AppointmentCard},
  props: ['showReminders','showAppointments','appointments'],
  data() {
    return {
      nav_height: 0,
      selectedOption: 0,
      reminders: [],
      syncing: false
    }
  },
  mounted() {
    EventBus.$on('getReminders',this.getReminders);
  },
  computed: {
    show() {
      return this.showReminders || this.showAppointments;
    },
    notifications_to_view() {
      const now = moment();
      if(this.selectedOption === 0) {
        return this.reminders.filter(not => {
          if(!not.readAt) return true;
          const readAtTime = moment(not.readAt, 'YYYY-MM-DD HH:mm:ss');
          const difference = now.diff(readAtTime, 'minutes');
          return difference < 5
        })
      } else {
        return this.reminders.filter(not => {
          const readAtTime = moment(not.readAt, 'YYYY-MM-DD HH:mm:ss');
          const difference = now.diff(readAtTime, 'minutes');
          return difference > 5
        })
      }
    },
    appointmentsToDisplay() {
      return this.selectedOption === 0 ? this.futureAppointments : this.pastAppointments;
    },
    pastAppointments() {
      return this.appointments.filter(booking => {
        const end = moment(booking.end);
        const now = moment();
        return Boolean(end < now);
      })
    },
    futureAppointments() {
      return this.appointments.filter(booking => {
        const end = moment(booking.end);
        const now = moment();
        return Boolean(end > now);
      })
    },
  },
  methods: {
    async getReminders() {
      if(this.syncing) return;
      this.syncing = true;
      const res = await this.api({
        path: '/api/u/notifications'
      })
      this.reminders = res.data;
      this.syncing = false;
    }
  }
}
</script>
<style lang="less">
#SideBar {
  position: absolute;
  right: 0;
  width: 0;
  height: 100%;
  z-index: 400;
  transition: width 0.25s ease-in;
  box-shadow: 0 4px 4px 0 #00000015;
  background-color: #112231;
  .noAppts {
    margin-top: 20px;
    margin-left: 15px;
    font-size: 1.5rem;
  }
  &.show {
    width: 400px;
  }
  .top {
    background-color: #4F4D46;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 73px;
      font-size: 1.4rem;
      color: white;
      cursor: pointer;
      border-bottom: #D9D9D9 2px solid;
      &.selected {
        border-bottom: #604BFF 2px solid;
        color: #E3E0FF;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      }
    }
  }
}
</style>