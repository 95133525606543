<template>
  <div id="Favourites" class="container">
    <h1>Favourites</h1>
    <div v-if="total_videos" class="favsSection">
      <h5 class="wellbeing"><span>Wellbeing<sup>+</sup></span> Videos</h5>
      <div class="listHolder">
        <div v-for="(item,i) in videos_on_this_page" class="myListItem" @mouseover="selected_video = i" @mouseleave="selected_video = - 1" :class="{selected: selected_video === i}">
          <MyListCard :item="item" :selected="selected_video=== i" @deleteMyListItem="deleteMyListItem" :favourites-card="true"/>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <b-pagination
            v-if="total_videos && total_videos > per_page"
            v-model="video_page"
            :total-rows="total_videos"
            :per-page="per_page"
            size="lg"
        ></b-pagination>
      </div>

    </div>

    <div v-if="total_modules" class="favsSection">
      <h5>Articles</h5>
      <div class="listHolder">
        <div v-for="(item,i) in modules_on_this_page" class="myListItem" @mouseover="selected_module = i" @mouseleave="selected_module = - 1" :class="{selected: selected_module === i}">
          <MyListCard :item="item" :selected="selected_module=== i" @deleteMyListItem="deleteMyListItem" :favourites-card="true"/>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <b-pagination
            v-if="total_modules && total_modules > per_page"
            v-model="module_page"
            :total-rows="total_modules"
            :per-page="per_page"
            size="lg"
        ></b-pagination>
      </div>

    </div>

    <div v-if="total_recipes" class="favsSection">
      <h5>Recipes</h5>
      <div class="listHolder">
        <div v-for="(item,i) in recipes_on_this_page" class="myListItem" @mouseover="selected_recipe = i" @mouseleave="selected_recipe = - 1" :class="{selected: selected_recipe === i}">
          <MyListCard :item="item" :selected="selected_recipe=== i" @deleteMyListItem="deleteMyListItem" :favourites-card="true"/>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <b-pagination
            v-if="total_recipes && total_recipes > per_page"
            v-model="recipes_page"
            :total-rows="total_recipes"
            :per-page="per_page"
            size="lg"
        ></b-pagination>
      </div>

    </div>

    <div v-if="total_podcasts" class="favsSection">
      <h5>Podcasts</h5>
      <div class="listHolder">
        <div v-for="(item,i) in podcasts_on_this_page" class="myListItem" @mouseover="selected_podcast = i" @mouseleave="selected_podcast = - 1" :class="{selected: selected_podcast === i}">
          <MyListCard :item="item" :selected="selected_podcast === i" @deleteMyListItem="deleteMyListItem" :favourites-card="true"/>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <b-pagination
            v-if="total_podcasts && total_podcasts > per_page"
            v-model="podcast_page"
            :total-rows="total_podcasts"
            :per-page="per_page"
            size="lg"
        ></b-pagination>
      </div>

    </div>
  </div>
</template>

<script>

import MyListCard from "@/components/MyList/MyListCard.vue";
import axios from "axios";
import config from "@/config/constants";
export default {
  name: "Favourites",
  components: {
    MyListCard
  },
  data() {
    return {
      videos: [],
      modules: [],
      podcasts: [],
      recipes: [],
      selected_video: -1,
      selected_module: -1,
      selected_recipe: -1,
      selected_podcast: -1,
      per_page: 3,
      video_page: 1,
      module_page: 1,
      recipes_page: 1,
      podcast_page: 1
    }
  },
  mounted() {
    this.getFavourites();
  },
  computed: {
    total_videos() {return this.videos.length},
    videos_on_this_page() {
      const start = this.video_page * this.per_page - this.per_page;
      const end = start + this.per_page - 1
      return this.videos.filter((item,i) => i >= start && i <= end);
    },
    total_modules() {return this.modules.length},
    modules_on_this_page() {
      const start = this.module_page * this.per_page - this.per_page;
      const end = start + this.per_page - 1
      return this.modules.filter((item,i) => i >= start && i <= end);
    },
    total_recipes() {return this.recipes.length},
    recipes_on_this_page() {
      const start = this.recipes_page * this.per_page - this.per_page;
      const end = start + this.per_page - 1
      return this.recipes.filter((item,i) => i >= start && i <= end);
    },
    total_podcasts() {return this.podcasts.length},
    podcasts_on_this_page() {
      const start = this.podcast_page * this.per_page - this.per_page;
      const end = start + this.per_page - 1
      return this.podcasts.filter((item,i) => i >= start && i <= end);
    },
  },
  methods: {
    async deleteMyListItem(id) {
      const allItems = this.videos.concat(this.modules,this.recipes,this.podcasts);
      const item = allItems.filter(item => item.id === id)[0];
      switch (item.product_id) {
        case 400:
          await this.unlikeArticle(item.content_id, 'module');
          break;
        case 3:
          await this.unlikeRecipe(item.content_id, 'recipe');
          break;
        case 18:
          await this.unlikePodcast(item.podcast_id);
          break;
        case 21: case 22:
          await this.unlikeStudioEvent(item.content_id);
          break;
      }
    },
    async unlikeStudioEvent(id) {
      const res = await this.api({
        path: 'api/u/studio/toggle-favourite-gym-event',
        event_id: id
      });
      if(res.success) {
        this.videos = this.videos.filter(item => item.content_id !== id)
      }
    },
    async unlikePodcast(id) {
      const res = await this.api({
        path: 'api/u/podcasts/subscribe',
        podcast_id: id
      })
      if(res.success) this.podcasts = this.podcasts.filter(item => item.podcast_id !== id)
    },
    async unlikeRecipe(id, type) {
      const res = await this.api({
        path: 'api-hc/unlike-item',
        type: type,
        resource_id: id
      });
      if(res.success) this.recipes = this.recipes.filter(item => item.content_id !== id)
    },
    async unlikeArticle(id, type) {
      const res = await this.api({
        path: 'api-hc/unlike-item',
        type: type,
        resource_id: id
      });
      if(res.success) this.modules = this.modules.filter(item => item.content_id !== id)
    },
    async getFavourites() {
      const res = await this.api({
        path: 'api/u/favourites'
      });
      if(res.success) {
        this.videos = res.studio_series;
        this.modules = res.modules;
        this.recipes = res.recipes;
        this.podcasts = res.podcasts
      }
    }
  }
}
</script>

<style lang="less">
#Favourites {
  h1 {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 3rem;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  h5 {
    font-size: 1.8rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    &.wellbeing {
      span {
        color: #87D2DD;
      }
    }
  }
  .listHolder {
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 15px;
    flex-wrap: wrap;
    .myListItem {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 15px;
    }
  }
  .favsSection {
    margin-bottom: 20px;
  }
  .pagination {
    background-color: black;
    li {
      span, button {
        background-color: white;
        color: black;
      }
    }
    .active {
      button {
        color: white;
        background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%);
        border-color: white;
      }
    }
    .page-item {
      &.disabled {
        span {
          color: grey !important;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .listHolder {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
  }
  @media only screen and (max-width: 750px) {
    .filters {
      grid-template-columns: 1fr;
    }
  }
}
</style>