<template>
  <div id="NavigationV2" class="position-relative">
    <div id="newNavTop" class="newNavTop" :class="{sideNavExpanded: sideNavExpanded}" ref="newTopNav">
      <div class="topNavLeftSide" :class="{sideNavExpanded: sideNavExpanded}">
        <div v-if="sideNavExpanded" class="imgHolder">
          <img
              ref="navLogo"
              @click="$store.getters.user.user_client_id !== 3519 ? $router.push('/') : $router.push('/personal/my-company')"
              class="nav-logo clickable"
              :src="getLogo"
              alt="company logo"
              @error="imageErrorHandler"
          />
        </div>
        <div class="topButton" @click="toggleSideNav">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
      </div>
      <div class="topNavMain text-white" :class="{sideNavExpanded: sideNavExpanded}" >
        <SearchBar class="searchBar" :placeholder="nav_elements.what_are_you_looking_for"/>
        <div class="navItems" ref="navItem">
          <div class="topNavItem" v-for="(item,i) in topNav" :key="'nav-item-1-'+i" @click="navigate(item)">
            {{item.label}}
            <div class="navDropDown bg-brand-primary" v-if="item.sub_items">
              <div class="navDropDownItem" v-for="sub in item.sub_items" @click="navigate(sub)">{{sub.label}}</div>
            </div>
          </div>
        </div>
        <div class="iconRow">
          <i @click="home" :class="iconHoveredOver === 4? 'fa-solid' : 'fa-light'" class="fa-home navIcon" @mouseover="iconHoveredOver = 4" @mouseleave="iconHoveredOver = -1"></i>
          <div class="navIcon hoverable" v-if="walletUser">
            <i class="fa-wallet" :class="iconHoveredOver === 1? 'fa-solid' : 'fa-light'"   @mouseover="iconHoveredOver = 1" @mouseleave="iconHoveredOver = -1"></i>
            <div class="iconDropDown walletHolder" @click="$router.push('/wallet')">
              <WalletCard />
            </div>
          </div>

<!--          <i class="fa-solid fa-trophy navIcon" @click="toggleChallenges" :class="{challengesOpen: showChallenges}" @mouseover="iconHoveredOver = 5" @mouseleave="iconHoveredOver = -1"></i>-->
          <div class="navIcon apptNavIcon" :class="bookingsExtended ? 'extended': null">
            <i @click="toggleBookingsExtended" :class="iconHoveredOver === 2 || bookingsExtended ? 'fa-solid' : 'fa-light'" class="fa-calendar-check navIcon" @mouseover="iconHoveredOver = 2" @mouseleave="iconHoveredOver = -1"></i>
            <div class="bookingAlert" v-if="showReminderAlert > 0"></div>
            <div v-if="showReminderAlert > 0" class="bookingAlertBox">
              <div class="bookingAlertBoxOuter" @mouseover="iconHoveredOver = 6" @mouseleave="iconHoveredOver = -1">
                <div class="bookingAlertBoxTriangle"></div>
                <div class="bookingAlertBoxInner">
                  <i class="fa-solid fa-circle-exclamation"></i>
                  {{ showReminderAlert > 1 ? 'You have bookings today, click here to view details' : 'You have a booking today, click here to view details' }}
                </div>
              </div>
            </div>
            <div class="iconDropDown apptHolder" >
<!--              <NavAppointmentHolder :bookings="bookings" @reload="getBookings" @close="bookingsExtended = false"/>-->
            </div>
          </div>
          <div class="navIcon reminderNavIcon" :class="remindersExtended ? 'extended': null">
            <i @click="toggleRemindersExtended" :class="iconHoveredOver === 3 || remindersExtended? 'fa-solid' : 'fa-light'" class="fa-bell navIcon" @mouseover="iconHoveredOver = 3" @mouseleave="iconHoveredOver = -1"></i>
            <div class="bookingAlert" v-if="unseenNotifications"></div>
            <div v-if="unseenNotifications" class="bookingAlertBox">
              <div class="bookingAlertBoxOuter" @mouseover="iconHoveredOver = 3" @mouseleave="iconHoveredOver = -1" v-if="!remindersExtended">
                <div class="bookingAlertBoxTriangle"></div>
                <div class="bookingAlertBoxInner">
                  <i class="fa-solid fa-circle-exclamation"></i>
                  Click the bell icon to see notifications
                </div>
              </div>
            </div>
            <div class="iconDropDown reminderDropDown">
<!--              <ReminderHolder :notifications="notifications" @close="remindersExtended = false"/>-->
            </div>
          </div>
        </div>
        <DarkModeToggler :nav_elements="nav_elements" v-if="darkModeAvailable"/>
      </div>
    </div>
    <div class="newNavSide" :class="{sideNavExpanded: sideNavExpanded}">
      <div class="sideNavItemsTop">
        <div class="sideNavItem" @click="home">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-house topIcon"></i>
            <div>{{nav_elements.home}}</div>
          </div>
        </div>
        <div v-for="(item,i) in nav" :key="'nav-item-2-'+i" @click="navigate(item)" class="hiddenSideNav" v-if="!adminMode">
          <div class="sideNavItem">
            <div class="sideNavItemInner" v-if="!item.sub_items"  @click="navigate(item)">
              <i v-if="item.icon" class="topIcon fa-solid" :class="item.icon"></i>
              <div>{{item.label}}</div>
            </div>
            <div class="sideNavItemInner" v-else @click="extendSideNav(i)">
              <i v-if="sideNavItemExtended === i" class=" topIcon fa-regular fa-chevron-up"></i>
              <i v-else class=" topIcon fa-regular fa-chevron-down"></i>
              <div>{{item.label}}</div>
            </div>
            <div class="sideNavDropDown" v-if="item.sub_items" :class="sideNavItemExtended === i ? 'extended':null">
              <div class="sideNavDropDownItem sideNavItem subDropDown" v-for="sub in item.sub_items" @click="navigate(sub)">
                <div class="sideNavItemInner pl-5">
                  <i v-if="sub.icon" class="fa-solid subIcon" :class="sub.icon"></i>
                  <div>{{sub.label}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(item,i) in sideNav" :key="'nav-item-3'+i" @click="navigate(item)" v-if="!adminMode">
            <div class="sideNavItem">
              <div class="sideNavItemInner" v-if="!item.sub_items"  @click="navigate(item)">
                <i class="topIcon fa-solid" :class="item.icon ? item.icon : 'fa-circle'"></i>
                <div>{{item.label}}</div>
              </div>
              <div class="sideNavItemInner" v-else @click="extendSideNav(i)">
                <i v-if="sideNavItemExtended === i" class=" topIcon fa-regular fa-chevron-up"></i>
                <i v-else class=" topIcon fa-regular fa-chevron-down"></i>
                <div>{{item.label}}</div>
              </div>
              <div class="sideNavDropDown" v-if="item.sub_items" :class="sideNavItemExtended === i ? 'extended':null">
                  <div class="sideNavDropDownItem sideNavItem subDropDown" v-for="sub in item.sub_items" @click="navigate(sub)">
                    <div class="sideNavItemInner pl-5">
                      <i class="fa-solid subIcon" :class="sub.icon ? sub.icon : 'fa-circle'"></i>
                      <div>{{sub.label}}</div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
<!--        <div class="sideNavItem">-->
<!--          <i class="fa-solid fa-chart-line"></i> My Progress-->
<!--        </div>-->
<!--        <div class="sideNavItem">-->
<!--          <i class="fa-solid fa-list-ul"></i> My List-->
<!--        </div>-->
<!--        <div class="sideNavItem">-->
<!--          <i class="fa-solid fa-clapperboard"></i>New Releases-->
<!--        </div>-->
      </div>

      <!--      Start of admin section-->
      <div class="sideNavItemsBottom" v-if="adminMode">

        <div class="sideNavItem" @click="navigateFromFixedButton('/customise')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-table-cells-large topIcon"></i>
            <div>{{admin_elements.branding_customise}}</div>
          </div>
        </div>

        <div class="sideNavItem" @click="navigateFromFixedButton('/custom-content')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-clapperboard topIcon"></i>
            <div>{{admin_elements.custom_content}}</div>
          </div>
        </div>

        <div class="sideNavItem" @click="navigateFromFixedButton('/data-analytics')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-chart-area topIcon"></i>
            <div>{{admin_elements.data_analytics}}</div>
          </div>
        </div>

        <div class="sideNavItem" @click="navigateFromFixedButton('/user-management')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-user topIcon"></i>
            <div>{{admin_elements.user_management}}</div>
          </div>
        </div>
      </div>
      <!--      End of admin section -->

      <div class="sideNavItemsBottom" v-if="!adminMode">
        <div class="sideNavItem" @click="navigateFromFixedButton('/my-progress')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-chart-line topIcon"></i>
            <div>{{nav_elements.my_progress}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="navigateFromFixedButton('/my-list')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-list-ul topIcon"></i>
            <div>{{nav_elements.my_list}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="navigateFromFixedButton('/favourites')">
          <div class="sideNavItemInner">
          <i class="fa-solid fa-star topIcon"></i>
            <div>{{nav_elements.favourites}}</div>
          </div>
        </div>
        <div class="sideNavItem">
          <div class="sideNavItemInner" @click="navigateFromFixedButton('/settings')">
          <i class="fa-solid fa-user topIcon"></i>
            <div>{{nav_elements.profile}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="logOut">
          <div class="sideNavItemInner">
          <i class="fa-solid fa-right-from-bracket topIcon" ></i>
            <div>{{nav_elements.logout}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="navigateFromFixedButton('/faqs')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-circle-info topIcon"></i> <div>FAQs</div>
          </div>
        </div>
        <div class="sideNavItem" @click="adminMode = !adminMode" v-if="isAdmin" >
          <div class="sideNavItemInner">
            <i class="fa-solid fa-gear topIcon"></i> <div>{{admin_btn_text}}</div>
          </div>
        </div>
<!--        <LanguageSelector class="sideNavItem" :title="nav_elements.choose_language"/>-->
      </div>

      <div class="sideNavItemsBottom" v-if="adminMode">
        <div class="sideNavItem" @click="logOut">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-right-from-bracket topIcon" ></i> <div>{{nav_elements.logout}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="adminMode = !adminMode" v-if="isAdmin">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-gear topIcon"></i> {{admin_btn_text}}
          </div>
        </div>
<!--        <LanguageSelector class="sideNavItem" :title="nav_elements.choose_language"/>-->
      </div>


<!--      <div class="adminBoxHolder">-->
<!--        <div class="adminBox">-->

<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="newNavContent" :class="{sideNavExpanded: sideNavExpanded}">
      <div class="w-100 position-relative">
        <SideBar :appointments="bookings" :reminders="notifications" :show-appointments="bookingsExtended" :show-reminders="remindersExtended"/>
        <slot></slot>
<!--          <Challenges :top-nav-height="topNavHeight" :show-challenges="showChallenges" @closeChallenges="closeChallenges"/>-->
      </div>
    </div>
  </div>
</template>
<script>

import SearchBar from "@/components/SearchBar.vue";
import {mapGetters} from "vuex";
import NavAppointmentHolder from "@/components/NavAppointmentHolder.vue";
import WalletCard from "@/components/DigitalClinics/Landing/WalletCard.vue";
import EventBus from "@/helpers/eventbus";
import moment from "moment";
import LanguageSelector from "@/components/Wellbeing/LanguageSelector.vue";
import Challenges from "@/components/Challenges/Challenges.vue";
import DarkModeToggler from "@/components/Home/New/DarkModeToggler.vue";
import ReminderHolder from "@/components/ReminderHolder.vue";
import SideBar from "@/components/SideBar.vue";

export default {
    name: "NavigationV2",
    components: {
      ReminderHolder,
      SearchBar,
      WalletCard,
      NavAppointmentHolder,
      Challenges,
      LanguageSelector,
      DarkModeToggler,
      SideBar
    },
    data() {
        return {
            sideNavExpanded: false,
            walletUser: false,
            iconHoveredOver: -1,
            bookings: [],
            notifications: [],
            sideNavItemExtended: -1,
            bookingsExtended: false,
            remindersExtended: false,
            showChallenges: false,
            topNavHeight: 77,
            adminMode: false,
            unseenNotifications: false,
            testNotifications: [
                {id: 1,
                  read_at: null,
                  created_at: "2023-09-01 12:21:37",
                  subject: "Your initial appointment is starting in 5 minutes",
                  body: "Your initial appointment with Roman Coach is starting in 5 minutes",
                  data: {
                    "actionUrl": {
                      href: "/total-mental-health"
                    }
                  }
                },
                {id: 0,
                  read_at: "2023-09-01 12:53:37",
                  created_at: "2023-09-01 12:53:37",
                  subject: "Your initial appointment is starting in 30 minutes",
                  body: "Your initial appointment with Roman Coach is starting in 30 minutes",
                  data: {
                    "actionUrl": {
                      href: "/total-mental-health"
                    }
                  }
                },
            ],
            defaultLightLogo: '/img/spectrum_life_logo_light.png',
            defaultDarkLogo: '/img/spectrum_life_logo_dark.png',
            errorWithLogo: false,
            unreadInterval: null
        }
    },
    methods: {
      imageErrorHandler(e) {
        this.errorWithLogo = true;
        e.target.src=this.darkMode ? this.defaultDarkLogo : this.defaultLightLogo;
      },
      toggleRemindersExtended() {
        this.updateNavHeight();
        this.bookingsExtended = false;
        this.remindersExtended = !this.remindersExtended;
        if(this.remindersExtended) {
          EventBus.$emit('getReminders');
          this.unseenNotifications = false;
        }
      },
      async checkForNotifications() {
        const res = await this.api({
          path: 'api/u/notifications/unread'
        })
        if(res.success) {
          this.unseenNotifications = res.unread_count && res.unread_count > 0;
        } else {
          this.unseenNotifications = false;
        }
      },
      closeChallenges() {
        this.showChallenges = false;
      },
      toggleChallenges() {
        this.updateNavHeight();
        this.showChallenges = !this.showChallenges;
      },
      navigateFromFixedButton(url) {
        if(url === '/faqs') {
          window.open('/faqs','_blank');
        } else {
          this.sideNavExpanded = false;
          this.$router.push(url)
        }

      },
      toggleSideNav() {
        EventBus.$emit("sideNavExpanded",!this.sideNavExpanded);
        this.sideNavExpanded = !this.sideNavExpanded;
      },
      toggleBookingsExtended() {
        this.updateNavHeight();
        if(this.showReminderAlert) this.$store.dispatch("setBookingReminderLastSeen");
        this.remindersExtended = false;
        this.bookingsExtended = !this.bookingsExtended;
      },
      extendSideNav(i) {
        if(i === this.sideNavItemExtended) {
          this.sideNavItemExtended = -1;
        } else {
          this.sideNavItemExtended = i;
        }
      },
      async getBookings(){
        try {
          let res = await this.api({
            path: "api/u/home-page/get-booking-widget",
          });
          if (res.success) {
            this.bookings = res.bookings;
            EventBus.$emit("setBookingReminders", this.bookings);

          }
          this.bookingsLoading = false;
        } catch (e) {
          this.bookingsLoading = false;
        }

      },
      logOut() {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch(err => {});
      },
      home() {
          this.$router.push({ name: "home" });
          this.sideNavExpanded = false;
      },
      navigate(item) {
        if(item.hasOwnProperty("sub_items")) return;
        this.sideNavExpanded = false;
        if (!item.external_link) {
          if(item.asset_id){
            this.$router.push({ name: item.item_slug, params: { id: item.asset_id }  });
          }else{
            this.$router.push({ name: item.item_slug });
          }
        } else {
          window.open("https://" + item.external_link, "_blank");
        }
      },
      toggleDarkMode() {
        this.$store.dispatch("setDarkMode",!this.darkMode)
      },
      async checkIfWalletUser() {
        let res = await this.api({
          path: "api/u/home/get-dc-banner",
        });
        if (res.success) {
          this.walletUser = res.dc_setting === "wallet";
        }
      },
      updateNavHeight() {
        this.topNavHeight = this.$refs.newTopNav.clientHeight;
      }
    },
    mounted() {      
      this.checkIfWalletUser();
      this.getBookings();
      this.checkForNotifications();
      const _t = this;
      EventBus.$on('updateDFDReminders',function() {
        setTimeout(()=> {_t.getBookings();},2000);
      });
      window.addEventListener('resize', this.updateNavHeight);
      this.updateNavHeight();
      this.$store.dispatch('setMyList');
      if(this.$route.name === 'challenges') {
        this.showChallenges = true
      }
      this.unreadInterval = setInterval(() => {
        if(!this.unseenNotifications) this.checkForNotifications();
      },1000 * 60)
    },
    beforeDestroy() {
      if(this.unreadInterval) {
        clearInterval(this.unreadInterval);
      }
    },
    destroyed() {
      window.removeEventListener('resize', this.updateNavHeight);
    },
    watch: {
      darkMode(val) {
        if(this.errorWithLogo) {
          this.$refs.navLogo.src = val ? this.defaultDarkLogo : this.defaultLightLogo;
        }
      },
      iconHoveredOver(val) {
        if(val !== 2 && val !== -1) this.bookingsExtended = false
      }
    },
    computed: {
        ...mapGetters(["hardcoded", "skin", "client", "nav", "darkMode","sideNav","bookingReminderLastSeen","navElements","skinsWithDarkMode","default_nav_elements","user","admin_nav_elements"]),
        topNav() {
          return this.nav;
        },
        isAdmin() {
          return this.user.user.employer_admin;
        },
        nav_elements() {
          let els = this.default_nav_elements;
          Object.entries(this.navElements).forEach(el => {
            const [key,value] = el;
            if(els.hasOwnProperty(key)) {
              els[key] = value.replace('&#39;',"'");
            }
          });
          return els;
        },
        admin_btn_text() {
          return this.adminMode ? this.admin_elements.exit_admin_view : this.admin_elements.enter_admin_view;
        },
        admin_elements() {
          let els = this.admin_nav_elements;
          Object.entries(this.navElements).forEach(el => {
            const [key,value] = el;
            if(els.hasOwnProperty(key)) {
              els[key] = value.replace('&#39;',"'");
            }
          });
          return els;
        },
        showReminderAlert() {
          const today = moment().format("YYYY-MM-DD");
          if(today === this.bookingReminderLastSeen) return false;
          let show = 0;
          this.bookings.map(item => {
            if(moment(item.start).format("YYYY-MM-DD") === today) show++;
          });
          return show;
        },
        darkModeAvailable() {
          return this.skinsWithDarkMode.includes(this.skin);
        },
        getLogo() {
          if (this.hardcoded && this.hardcoded.client_logo) {
            return this.hardcoded.client_logo;
          } else if (this.skin === 8) {
            return "/img/boi/boi-logo-alt.png";
          } else {
            if(this.client.client_logo === "https://healthcoach-media.com/res/images/esp/customise/spectrum-life-logo.png") {
              return this.darkMode ? this.defaultDarkLogo : this.defaultLightLogo;
            }
            return this.client.client_logo
          }
        },
    }
}
</script>
<style lang="less">

</style>