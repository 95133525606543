<template>
  <div id="ForgotPasswordV2">
    <div class="logoContainer">
      <img :src="getLogo" onerror="javascript:this.src='/img/logo-spectrum-life.svg'" alt="company logo"/>
    </div>
    <div class="title">Forgot Your Password?</div>
    <b-form class="mt-5">
      <b-form-group
          id="input-group-1"
          :label="getText.notLoggedIn.signUp.email"
          label-for="inputEmail"
      >
        <b-form-input
            type="email"
            id="inputEmail"
            v-model="email"
        ></b-form-input>
        <b-form-invalid-feedback id="user-name-error">
          {{ getText.notLoggedIn.login.usernameWarning }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div v-if="showMsg" class="showMsg" :class="{success: success}">{{msg}}</div>
      <b-button
          type="button"
          @click="sendReset"
          class="py-3 mt-4"
          variant="cta"
          :disabled="!validEmail"
      >{{ componentText.buttonText}}</b-button
      >
    </b-form>
    <div class="mt-5 d-flex justify-content-center align-items-center">
      <a class="text-link" @click="$emit('signIn')">{{componentText.back}}</a>
    </div>
  </div>

</template>

<script>

import { mapGetters } from "vuex"
import config from "@/config/constants";
import axios from "axios";

export default {
  name: "ForgotPasswordV2",
  data() {
    return {
      email: null,
      showMsg: false,
      msg: null,
      success: false
    }
  },
  methods: {
    async sendReset() {
      const payload = {
        email: this.email,
        return_domain: config.return_domain,
        lang: this.$store.state.lang
      };

      let res = await axios.post(
          config.api_env + "/application/api-hc/send-reset",
          payload,
          config.options
      );
      if(res.data.success){
        this.success = true;
        this.msg = res.data.message;
        this.showMsg = true;
      }else{
        this.success = true;
        this.msg = this.componentText.errorMessage
        this.showMsg = true;
      }
      setTimeout(()=> {this.showMsg = false}, 10000)
    }
  },
  computed: {
    ...mapGetters(["hardcoded", "skin"]),
    validEmail() {
      return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.email);
    },
    componentText(){
      return this.getText.notLoggedIn.forgotPassword
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
  },
};
</script>
<style lang="less">
#ForgotPasswordV2 {
  width: 100%;
  height: 100%;
  padding: 40px 100px;
  * {
    font-family: 'DMSans', sans-serif;
  }
  .showMsg {
    font-size: 1.4rem;
    color: darkred;
    &.success {
      color: darkgreen;
    }
  }
  .logoContainer {
    height: 100px;
    width: 100%;
    img {
      float: right;
      width: 40%;
      margin-right: 10px;
    }
  }
  .title {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 3rem;
    text-align: center;
  }
  label {
    font-size: 1.8rem;
  }
  button {
    width: 100%;
    border-radius: 10px !important;
    padding: 15px 0 !important;
    font-family: "FilsonPro", sans-serif;
    font-size: 1.75rem;
    background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
    &:active {
      transform: translateY(2px);
    }
    &.disabled {
      background: grey;
    }
  }
  input, .form-control {
    all: unset;
    padding: 5px;
    height: 25px;
    width: 100%;
    margin: 0;
    font-family: 'DMSans', sans-serif;
    font-size: 1.4rem;
    background-color: transparent !important;
    border-bottom: #DCDBDD 1px solid;
    &:active, &:focus {
      border-image: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%) 100 !important;
      //border-bottom: transparent;
      border-width: 1px;
      border-style: solid;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
  a {
    background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    &:hover {
      background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }
  }
}
</style>
