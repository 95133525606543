<template>
  <div id="UserRegister">
    <div class="title">{{ componentText.title }}</div>

<!--    <ThirdPartyLogin :show-ms-sso="showMsSso" :show-google-sso="showGoogleSso"/>-->
    <div class="orDivider" v-if="showGoogleSso || showMsSso">
      <div class="divider"></div>
      <div class="or">or</div>
      <div class="divider"></div>

    </div>
    <b-form>
      <div>
        <b-form-group
            id="input-group-3"
            :label="componentText.email"
            label-for="signupform-username"
        ><b-form-input
            type="text"
            :class="{invalid: !validEmail}"
            id="signupform-username"
            v-model="newUser.username"
            required
        ></b-form-input>
        </b-form-group>

        <div class="nameHolder">
          <b-form-group
              id="input-group-1"
              :label="componentText.firstName"
              label-for="signupform-firstname"
          ><b-form-input
              type="text"
              id="signupform-firstname"
              v-model="newUser.firstname"
              :class="{invalid: newUser.firstname.length < 1}"
              required
          ></b-form-input>
          </b-form-group>
          <div></div>
          <b-form-group
              :label="componentText.lastName"
              id="input-group-2"
              label-for="signupform-lastname"
          >
            <b-form-input
                type="text"
                id="signupform-lastname"
                v-model="newUser.lastname"
                :class="{invalid: newUser.lastname.length < 1}"
                required
            ></b-form-input>
          </b-form-group>
        </div>

        <b-form-group
            id="input-group-5"
            :label="componentText.password"
            label-for="newPassword"
        >
          <div class="password-wrap">
            <b-form-input
                :type="passwordFieldType"
                id="newPassword"
                v-model="$v.newPassword.$model"
                required
                :class="{invalid: $v.newPassword.$error}"
            ></b-form-input>
            <div class="eye" @click="switchVisibility">
              <font-awesome-icon
                  v-if="passwordFieldType === 'password'"
                  icon="eye"
              ></font-awesome-icon>
              <font-awesome-icon
                  v-else
                  icon="eye-slash"
              ></font-awesome-icon>
            </div>
          </div>
        </b-form-group>

        <div class="validationBox">
          <span :class="[tenChars?'passed':'failed']"><font-awesome-icon icon="times" v-if="!tenChars"/><font-awesome-icon icon="check" v-else></font-awesome-icon> At least 10 characters</span><br>
          <span :class="[upperCase?'passed':'failed']"><font-awesome-icon icon="times" v-if="!upperCase"></font-awesome-icon><font-awesome-icon icon="check" v-else></font-awesome-icon> At least 1 uppercase letter</span><br>
          <span :class="[lowerCase?'passed':'failed']"><font-awesome-icon icon="times" v-if="!lowerCase"></font-awesome-icon><font-awesome-icon icon="check" v-else></font-awesome-icon> At least 1 lowercase letter</span><br>
          <span :class="[contNumber?'passed':'failed']"><font-awesome-icon icon="times" v-if="!contNumber"></font-awesome-icon><font-awesome-icon icon="check" v-else></font-awesome-icon> At least 1 number</span><br>
          <span :class="[specChar?'passed':'failed']"><font-awesome-icon icon="times" v-if="!specChar"></font-awesome-icon><font-awesome-icon icon="check" v-else></font-awesome-icon> At least 1 special character</span>
        </div>

        <b-form-group
            id="input-group-4"
            :label="componentText.orgCode"
            label-for="signupform-org_code"
        >
          <b-form-input
              type="text"
              id="signupform-org_code"
              v-model="newUser.org_code"
              @change="getOrgLocations"
              required
              :class="{invalid: org_code_invalid}"
              :disabled="fixedCode"
          ></b-form-input>
        </b-form-group>

        <div class="nameHolder">
          <b-form-group
              v-if="organisation && organisation.locations.length > 1"
              id="input-group-7"
              :label="componentText.location"
              label-for="signupform-locations"
          ><b-form-select
              id="signupform-locations"
              v-model="newUser.location"
              class="form-control"
              required
              :class="{invalid: newUser.location == null}"
          >
            <option :value="null" disabled>Select a location... </option>
            <option
                v-for="location in organisation.locations"
                :value="location.location_id"
            >{{ location.location_name }}</option
            >
          </b-form-select>
          </b-form-group>
          <div v-if="organisation && organisation.locations.length > 1"></div>
          <b-form-group
              v-if="
                  organisation &&
                    organisation.member_levels &&
                    organisation.member_levels !== 'null' &&
                    organisation.member_levels.length > 1
                "
              id="input-group-8"
              :label="componentText.memberLevel"
              label-for="signupform-member_level"
          >
            <b-form-select
                id="signupform-member_level"
                class="form-control"
                v-model="newUser.member_level"
                :options="organisation.member_levels"
                required
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="termsHolder">
          <div class="checkBoxHolder" @click="newUser.terms = !newUser.terms" :class="{accepted: newUser.terms}">
            <font-awesome-icon size="lg" icon="check" v-if="newUser.terms"></font-awesome-icon>
          </div>
          <div class="terms">
            {{ componentText.agreePrivacy }} <a target="_blank" href="/privacy-policy">{{ componentText.privacy }}</a>
            {{componentText.and}} <a target="_blank" href="/terms-and-conditions"> {{ componentText.tcs }}</a>
          </div>
        </div>
      </div>
      <div
          id="signup-button"
          class="submit-form d-flex align-items-center justify-content-center"
      >
        <b-button
            @click="signUp"
            class="py-3 signUpBtn"
            :disabled="signUpDisabled"
        >{{ componentText.buttonText }}</b-button>
      </div>
      <b-tooltip
          v-if="signUpDisabled"
          target="signup-button"
          triggers="hover"
          custom-class="stats-tooltip"
      >{{ componentText.validate }}</b-tooltip>
    </b-form>
    <div class="new-here">
      <a @click="$emit('switchForm')" class=" text-link">{{
          componentText.hasAccount
        }}</a>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, sameAs} from "vuelidate/lib/validators";
import axios from "axios";
import config from "@/config/constants";
import {mapGetters} from "vuex";
import ThirdPartyLogin from "@/components/NotLoggedIn/ThirdPartyLogin.vue";
import LanguageSelect from "@/components/LanguageSelect.vue";


export default {
  name: "RegisterUser",
  components: {LanguageSelect, ThirdPartyLogin},
  mixins: [validationMixin],
  data() {
    return {
      user: {
        email: ""
      },
      newUser: {
        org_code: "",
        location: null,
        terms: false,
        firstname: '',
        lastname: ''
      },
      isSignUp: false,
      organisation: false,
      fixedCode: false,
      locations: [],
      signUpError: false,
      popUpContent: {},
      passwordFieldType: "password",
      newPassword: '',
      repeatPassword: '',
      tenChars: false,
      upperCase: false,
      lowerCase: false,
      contNumber: false,
      specChar: false,
      pw_flag: false,
      locationValid: true,
      org_code_invalid: true
    };
  },
  validations: {
    newPassword: {
      required,
      minLength: function(value) {
        this.tenChars = value.length > 9;
        return value.length > 9;
      },
      containsUppercase: function(value) {
        this.upperCase = /[A-Z]/.test(value);
        return /[A-Z]/.test(value);
      },
      containsLowercase: function(value) {
        this.lowerCase = /[a-z]/.test(value);
        return /[a-z]/.test(value);
      },
      containsNumber: function(value) {
        this.contNumber = /[0-9]/.test(value);
        return /[0-9]/.test(value);
      },
      containsSpecial: function(value) {
        this.specChar = /[^a-zA-Z0-9\-\/]/.test(value);
        return /[^a-zA-Z0-9\-\/]/.test(value);
      }
    },
  },
  mounted() {
    if (this.$route.query.org) {
      //if the org param is in the url, prefill the form and make api call
      this.newUser.org_code = this.$route.query.org;
      this.fixedCode = true;
      this.getOrgLocations();
    } else if (
        this.$store.getters.hardcoded &&
        this.$store.getters.hardcoded.org_code
    ) {
      this.newUser.org_code = this.$store.getters.hardcoded.org_code;
      this.fixedCode = true;
      this.getOrgLocations();
    }
  },
  methods: {
    switchForm() {
      this.isSignUp = !this.isSignUp;
    },
    async signUp() {
      const payload = {
        username: this.newUser.username,
        firstname: this.newUser.firstname,
        lastname: this.newUser.lastname,
        password: this.newPassword,
        password_repeat: this.newPassword,
        organisation_code: this.newUser.org_code,
        location_id: this.newUser.location,
        member_level: this.newUser.member_level,
        platform: "web",
        lang: this.$store.state.lang
      };
      let res = await axios.post(
          config.api_env + "/application/api-hc/signup",
          payload,
          config.options
      );
      if (res.data.success) {
        //this.$router.push('/verify').catch(err => {})
        //Removed verify redirect on 17.06.19 - log user in and go to home route
        const user = {
          user_token: res.data.user_id,
          user_info: res.data.log_in_res
        };
        await this.$store.dispatch("setNavType",res.data.log_in_res.home_page);
        const home = await this.$store.dispatch("loginAs", user);
        //for sanusx push straight to insights after registration
        this.$store.getters.skin === 13 ? this.$router.push({name: 'insights'}) : this.$router.push(home);
      } else {
        this.$store.dispatch("setWarning", res.data.err);
      }
    },
    async getOrgLocations() {
      let payload = {
        org_code: this.newUser.org_code,
        lang: this.$store.state.lang
      };
      try {
        let res = await axios.post(
            config.api_env + "/application/api-hc/get-client-signup-options",
            payload,
            config.options
        );
        if (res.data.success) {
          this.organisation = res.data;
          this.org_code_invalid = false;
          if (this.organisation.locations.length > 1) {
            this.newUser.location = null;
            this.locationValid = false;
          } else if (this.organisation.locations.length === 1) {
            this.newUser.location = this.organisation.locations[0].location_id;
            this.locationValid = true;
          }
          if(this.organisation.member_levels.length > 0) {
            this.newUser.member_level = this.organisation.member_levels[0];
          }
        } else {
          this.popUpContent = {
            header: "Oops!",
            message: res.data.err,
            buttonText: "OK"
          };
          this.signUpError = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    hidePopUp() {
      this.signUpError = false;
    },
    switchVisibility() {
      this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
    }
  },
  computed: {
    ...mapGetters(["hardcoded", "skin","client"]),
      showGoogleSso() {
        return this.client.google_sso_enabled === 1;
      },
      showMsSso() {
        return this.client.ms_sso_enabled === 1;
      },
     passwordStrength() {

     },
     validEmail() {
       return /^[\w+.-]+@([\w-]+\.)+[\w-]{2,10}$/g.test(this.newUser.username);
    },
    signUpDisabled() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return true;
      }
      if (this.newUser.terms && this.newUser.firstname.length > 0 && this.newUser.lastname.length > 0 && this.validEmail) {
        if(!this.newUser.org_code || this.org_code_invalid) return true;
        if(!this.locationValid) return true
        if (
            this.organisation &&
            this.organisation.member_levels &&
            this.organisation.member_levels.length > 1 &&
            !this.newUser.member_level
        ) {
          //member level must be selected if there's more than one level to choose
          return true;
        }
        return false;
      } else return true;
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
    privacy() {
      if (this.$store.getters.skin === 8) {
        return "/img/boi/BOI-Wellbeing-Privacy-Policy.pdf";
      } else {
        return "/privacy-policy";
      }
    },
    terms() {
      if (this.$store.getters.skin === 8) {
        return "/img/boi/BOI-Wellbeing-TCs.pdf";
      } else {
        return "/terms-and-conditions";
      }
    },
    componentText() {
      return this.getText.notLoggedIn.signUp;
    }
  },
  watch: {
    'newUser.location': function(value) {
      this.locationValid = this.organisation.locations.length < 1 || value !== null;
    }
  }
}

</script>

<style lang="less">
#UserRegister {
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 40px 100px;
  * {
    font-family: 'DMSans', sans-serif;
  }
  .row {
    margin: 0 !important;
  }
  .title {
    text-align: center;
    font-size: 2.4rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
  }
  .orDivider {
    margin: 25px 0 15px 0;
    align-items: center;
    justify-content: center;
    display: flex;

    .divider {
      height: 1px;
      width: 43%;
      background: #DCDBDD;
    }
    .or {
      width: 14%;
      text-align: center;
      font-size: 1.5rem;
    }
  }
  .nameHolder {
    display: grid;
    grid-template-columns: 1fr 8px 1fr;
    margin-top: 25px;
    .form-group {
      margin-top: 0 !important;
    }
  }
  .validationBox {
    margin-bottom: 15px;
  }
  .form-group {

    position: relative;
    border-radius: 15px;
    margin-top: 25px;
    label {
      position: absolute;
      top: -7px;
      left: 10px;
      background: white;
      font-size: 1.3rem;
      line-height: 1.2rem;
    }
    input, input:focus {
      border: 1px #DDDDDB solid;
      padding: 20px 15px !important;
      border-radius: 10px;
      font-size: 16px;
      line-height: 15px;
      &.invalid {
        border: 1px darkred solid;
      }
    }
    select {
      border: 1px #DDDDDB solid;
      border-radius: 10px;
      font-size: 16px;
      padding: 10px;
      height: fit-content;
      &.invalid {
        border: 1px darkred solid;
      }
    }
    .eye {
      position: absolute;
      top: 5px;
      right: 15px;
      font-size: 20px;
    }

  }
  .termsHolder {
    grid-template-columns: 40px 1fr;
    margin-top: 20px;
    margin-bottom: 25px;
    display: grid;
    .checkBoxHolder {
      width: 25px;
      height: 25px;
      border: 1px grey solid;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      svg {
        color: white;
      }
      &.accepted {
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
      }
    }
    .terms {
      font-size: 1.5rem;
      line-height: 1.9rem;
      text-align: justify;
      margin-left: 20px;
      a {
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
        &:hover {
          background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          cursor: pointer;
        }
      }
    }
  }
  .signUpBtn {
    width: 100%;
    border-radius: 10px;
    padding: 15px 0 !important;
    font-family: "FilsonPro", sans-serif;
    font-size: 1.75rem;
    background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
    &.disabled {
      background: grey !important;
    }
  }
  .new-here {
    margin-top: 40px;
    a {
      background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
      font-size: 1.5rem;
    }

  }
}
</style>