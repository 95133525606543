<template>
  <div id="DataAnalytics" class="content">
    <transition name="fade" mode="out-in">
      <div class="w-100">

        <div class="text-left mt-4 mb-3">
          <div class="row page-title mobile-text-center-desktop-left">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <h2>Monthly Utilisation Reports</h2>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row reports-filter-buttons pb-4 pt-4">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <b-form-input type="month" v-model="date" @change="loadReports" min="2018-03" />
            </div>
            <!-- <div class="col-md-4">
                <b-form-input type="text" v-model="filter" placeholder="Search Here" />
            </div> -->
            <div class="col-md-4"></div>
          </div>
          <div class="row reports-list">
            <div class="col-md-2"></div>
            <div v-if="filteredReportList.length > 0" class="col-md-8 mt-5 mb-3">
              <b-button
                  :variant="report.url ? 'success' : 'primary'"
                  @click="downloadReport(report)"
                  block
                  v-for="report in filteredReportList"
              >
                {{ report.url ? 'Ready To View - ' + report.label : '' + report.label }}
              </b-button>
            </div>
            <div v-else class="col-md-8 mt-5">
              <b-alert variant="danger" show class="text-center">
                No reports found for this month.
              </b-alert>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>

        <!--        TODO: This section must be deleted-->
<!--        <div style="width: 100%; max-width: 1000px; margin: auto">-->
<!--          <h2>Recommendations</h2>-->
<!--          <img src="/img/data_test.png" style="display: block; width: 100%; height: auto"/>-->
<!--        </div>-->


        <!--        End of section to delete-->
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DataAnalytics',
  data () {
    return {
      date: null,
      downloadUrl: null,
      filter: '',
      loading: true,
      reportList: [],
    }
  },
  mounted () {
    // It gets current year and last month
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    this.date = year + '-' + (month < 10 ? '0' + month : month);
    this.loadReports();
    this.loading = false;
  },
  computed: {
    filteredReportList() {
      if (!this.filter) {
        return this.reportList;
      }
      return this.reportList.filter(report => {
        return report.label.toLowerCase().includes(this.filter.toLowerCase());
      });
    }
  },
  methods: {
    downloadReport(report) {
      /**
       * If the report has a URL, then open it in a new tab.
       */
      if (report.url) {
        window.open(report.url);
        return false;
      }

      const token = this.$store.getters.user.user_token;
      const data = {
        'user_id': token,
        'key': report.key
      };
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/client-report/download-client-report';
      const opts = {
        method: 'post',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(data)
      };
      this.loading = true;
      fetch(url, opts)
          .then(response => response.blob())
          .then(blob => {
            const file = new File([blob], 'report.pdf', { type: 'application/pdf' });
            const fileUrl = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = 'report.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    loadReports() {
      const token = this.$store.getters.user.user_token;
      const data = {
        'user_id': token,
        'date': this.date
      };
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/client-report/get-client-report';
      const opts = {
        method: 'post',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(data)
      }
      this.loading = true;
      fetch(url, opts).then(response => {
        response.json().then(data => {
          if(data.success == true){
            this.reportList = data.data;
            this.loading = false;
          }
        });
      });
    }
  }
}
</script>

<style lang="less">
#DataAnalytics {
  width: 100%;
  min-height: calc(100vh - 200px);
  background-color: white;
  font-family: "FilsonPro", sans-serif;
  h2 {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
    margin-top: 20px;
  }
  input, .alert-danger {
    font-size: 1.8rem;
  }
  .reports-filter-buttons {
    background-color: white;
  }
  .reports-list {
    background-color: #f6f6f6;
    padding-top: 0px;
    padding-bottom: 30px;
  }
}

</style>