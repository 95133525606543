<template>
  <div id="fitness">
    <div class="page-header py-5 bg-transparent" :class="{ accessCode: $store.getters.user.access_code_user}">
      <div
        class="content-container row d-block py-md-5 py-0" style="min-height: 420px" v-if="$store.getters.subdomain !== 'pwcire' && !$store.getters.user.access_code_user"
      >
        <div class="page-title col-12 d-block d-md-none pl-0">
          <h2 class="text-center">{{$route.meta.title ? $route.meta.title : componentText.title}}</h2>
        </div>
        <div class="row">
          <div class="d-block col-12 col-md-4 col-lg-5 col-xl-6">
            <div class="page-title col-md-12">
              <h2 class="d-none d-md-block">{{$route.meta.title ? $route.meta.title : componentText.title}}</h2>
              <div class="todays-stats my-5 text-center text-md-left">
                <h3 class="py-2">{{componentText.stats}}</h3>
                <div class="stats-box mt-5">
                  <div class="distance">
                    <div class="score">
                      <p>{{componentText.distance}} <span class="bold">{{ distance }}</span></p>
                    </div>
                    <div class="target">
                      <p>{{componentText.targetKM}} <span class="bold">{{(   Math.round(fitnessStats.target_km * 10) / 10).toFixed(2) }} Km</span></p>
                    </div>
                  </div>
                  <div class="steps">
                    <div class="score">
                      <p>{{componentText.steps}} <span class="bold">{{ steps }}</span></p>
                    </div>
                    <div class="target">
                      <p >{{componentText.targetSteps}} <span class="bold">{{ fitnessStats.target_steps }}</span></p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!user.insights_completed"
                class="my-5 insights__btn_container"
              >
                <router-link
                  to="/personal/insights"
                  class="btn btn-lg btn-event btn-outline py-3"
                  type="button"
                  
                >
                  {{componentText.insights}}
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-7 col-xl-6" v-if="!loadingStats">
            <PersonalStatsCalendar
              v-on:previous="calendarPrevWeek"
              v-on:next="calendarNextWeek"
              v-on:select-date="selectDate"
              :calendar="calendar"
              :target="targetSteps"
              :label="componentText.stepsLabel"
            >
            </PersonalStatsCalendar>
          </div>
        </div>
      </div>
      <div class="toolbar col-12 d-none d-lg-block order-3">
        <b-button-group>
          <b-button
            class="px-4 py-4 col-4"
            :class="{ active: page === 0, 'col-6': $store.getters.user.access_code_user }"
            @click="pageSelect(0)"
            >{{componentText.plan}}
          </b-button>
          <b-button
            class="px-4 py-4 col-4"
            :class="{ active: page === 1, 'col-6 rounded-edge': $store.getters.user.access_code_user }"
            @click="pageSelect(1)"
            >{{componentText.search}}
          </b-button>
          <b-button
            class="px-4 py-4 col-4"
            :class="{ active: page === 2 }"
            @click="pageSelect(2)"
            v-if="$store.getters.subdomain !== 'pwcire' && !$store.getters.user.access_code_user"
            >{{componentText.history}}
          </b-button>
        </b-button-group>
      </div>
    </div>
    <div class="col-12 d-block d-lg-none order-3 py-5">
      <div class="dropdown-wrap">
        <b-dropdown :text="dropdownText" class="dropdown cta-button py-2">
          <b-dropdown-item @click="pageSelect(0, 'WORKOUT PLAN')"
            >{{componentText.plan}}
          </b-dropdown-item>
          <b-dropdown-item @click="pageSelect(1, 'EXERCISE SEARCH')"
            >{{componentText.search}}
          </b-dropdown-item>
          <b-dropdown-item @click="pageSelect(2, 'HISTORY')" v-if="$store.getters.user.access_code_user"
            >{{componentText.history}}</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
    <AllWorkoutPlans
      v-if="page === 0"
      v-on:select-plan="selectPlan"
    ></AllWorkoutPlans>
    <WorkoutPlan v-if="page === 4" :plan="selectedPlan"></WorkoutPlan>
    <ExerciseSearch v-if="page === 1"></ExerciseSearch>
    <ExerciseHistory v-if="page === 2"></ExerciseHistory>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import AllWorkoutPlans from "../components/Fitness/AllWorkoutPlans.vue";
import WorkoutPlan from "../components/Fitness/WorkoutPlan.vue";
import ExerciseSearch from "../components/Fitness/ExerciseSearch.vue";
import ExerciseHistory from "../components/Fitness/ExerciseHistory.vue";
import PersonalStatsCalendar from "../components/PersonalStatsCalendar.vue";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "Fitness",
  components: {
    AllWorkoutPlans,
    WorkoutPlan,
    ExerciseSearch,
    ExerciseHistory,
    PersonalStatsCalendar
  },
  data() {
    return {
      loadingStats: false,
      fitnessStats: {},
      calendar: {
        calendarWeek: "",
        calendarDates: [],
        calendarFullDates: [],
        weekStart: 1,
        weekEnd: 7,
        weeklySteps: [],
        hasSteps: false,
        selectedDate: "",
        selectedFullDate: ""
      },
      page: 0,
      selectedPlan: "",
      dropdownText: "WORKOUT PLAN",
      steps: 0,
      distance: 0,
      target: 0
    };
  },
  mounted() {
    this.initCalendar(this.calendar.weekStart, this.calendar.weekEnd);
    const today = moment().format("DD");
    this.calendar.selectedDate = this.calendar.calendarDates.findIndex(
      x => x === today
    );
    this.calendar.selectedFullDate = this.calendar.calendarFullDates[
      this.calendar.selectedDate
    ];
    //check if user was sent here by selecting on specific plan on homepage
    if (this.$route.params.setPage) {
      this.page = this.$route.params.setPage;
      this.selectedPlan = this.$route.params.setSelectedPlan;
    }
  },
  methods: {
    async getMyFitnessStats(start) {
      const week = moment()
        .isoWeekday(start)
        .format("MM-DD-YYYY");
      const payload = {
        user_id: this.$store.getters.user_id,
        start_date: week
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/get-surge-fitness-stats",
        payload,
        config.options
      );
      this.fitnessStats = res.data;
      this.distance = this.fitnessStats.today_distance_km;
      this.steps = this.fitnessStats.today_steps;
      this.calendar.weeklySteps = Object.values(this.fitnessStats.step_stats);
      this.calendar.hasSteps = this.calendar.weeklySteps.some(
        steps => steps > 0
      );
      this.target = this.fitnessStats.target_steps;
      this.loadingStats = false;
    },
    initCalendar(start, end) {
      this.getMyFitnessStats(start);
      for (let i = 0; i < 7; i++) {
        this.calendar.calendarDates[i] = moment()
          .isoWeekday(start + i)
          .format("DD");
        this.calendar.calendarFullDates[i] = moment()
          .isoWeekday(start + i)
          .format("MM-DD-YY");
      }
      this.calendar.calendarWeek =
        moment()
          .isoWeekday(start).locale(this.$store.state.lang)
          .format("DD MMM") +
        " - " +
        moment()
          .isoWeekday(end).locale(this.$store.state.lang)
          .format("DD MMM");
      const today = moment().format("DD");
      this.calendar.selectedDate = this.calendar.calendarDates.findIndex(
        x => x === today
      );
    },
    calendarPrevWeek() {
      this.calendar.weekStart = this.calendar.weekStart - 7;
      this.calendar.weekEnd = this.calendar.weekEnd - 7;
      this.initCalendar(this.calendar.weekStart, this.calendar.weekEnd);
    },
    calendarNextWeek() {
      this.calendar.weekStart = this.calendar.weekStart + 7;
      this.calendar.weekEnd = this.calendar.weekEnd + 7;
      this.initCalendar(this.calendar.weekStart, this.calendar.weekEnd);
    },
    pageSelect(page, text) {
      this.page = page;
      if (text) {
        this.dropdownText = text;
      }
    },
    selectPlan(id) {
      this.selectedPlan = id;
      this.page = 4;
    },
    selectDate(date) {
      const week = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ];
      this.calendar.selectedDate = date;
      this.calendar.selectedFullDate = this.calendar.calendarFullDates[date];
      this.distance = this.fitnessStats.distance_stats[week[date]];
      this.steps = this.fitnessStats.step_stats[week[date]];
    }
  },
  computed: {
    ...mapState(["user"]),
    skin() {
      return this.$store.getters.skin;
    },
    targetSteps() {
      return this.fitnessStats.target_steps
        ? this.fitnessStats.target_steps
        : 0;
    },
    componentText() {
      return this.getText.loggedIn.fitness;
    },
  }
};
</script>
<style lang="less">
#fitness {
  .page-title {
    text-align: left;

    h2 {
      text-align: left;
      font-size: 3.8rem;
      letter-spacing: 0.7rem;
      font-family: "FilsonProBold", sans-serif;
      color: #fff;
    }
  }
  .todays-stats{
    font-size: 1.8rem;
    p,h3 {
      color: #fff;
      .bold {
        font-family: "FilsonProBold", sans-serif;
      }
    }
    .stats-box{
      border: 2px solid #fff;
      .distance{
        border-bottom: 2px solid #fff
      }
      .target{
        background-color: rgba(206, 206, 206, 0.25);
        border-top: 2px solid #fff;
      }
      p{
        padding: 5px 10px;
        margin: 0;
      }
    }
  }
  .toolbar {
    text-align: center;
    z-index: 10;
    .btn-group {
      width: 700px;
      height: 60px;
      .btn {
        font-family: "DMSans", sans-serif;
        font-size: 1.6rem;
        border-color: #fff;
        border-width: 1px;
        letter-spacing: 0;
        &:first-child {
          border-radius: 60px 0 0 60px;
        }

        &:nth-child(2) {
          border-radius: 0;
        }

        &:nth-child(3) {
          border-radius: 0 60px 60px 0;
        }
      }
    }
  }

  .dropdown-wrap {
    text-align: center;
    font-size: 1.6rem;
    width: 300px;
    margin: auto;
    position: relative;

    .dropdown {
      position: relative;
      border-width: 0;
      border-radius: 10px;
      font-size: 1.6rem;
      width: 300px;
      text-align: center;
      margin: auto;
      box-shadow: none;

      &:focus {
        border-radius: 10px 10px 0 0;
        box-shadow: none;
      }

      &.show {
        border-radius: 10px 10px 0 0;

        .dropdown-toggle {
          background: none;
        }
      }

      .dropdown-toggle {
        &:active {
          background: none;
        }
      }

      .dropdown-menu {
        margin-top: 0;
        width: 100%;
        font-size: 1.6rem;
        border-radius: 0 0 10px 10px;
      }
    }
  }

  .btn-event {
    font-size: 16px;
    background: transparent;
    color: #ffffff;
    border: 1px solid #fff;
    border-radius: 25px;
  }
  .rounded-edge{
    border-radius: 0 60px 60px 0 !important;
  }
}
</style>
