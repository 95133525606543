<template>
  <div id="LoginV2">
    <div class="loginDarkModeContainer" v-if="darkModeAvailable">
      <DarkModeToggler :nav_elements="nav_elements"/>
    </div>
    <div class="imageContainer"></div>
    <div class="loginContainer">
      <div class="innerLoginContainer">
        <ResetPasswordV2 v-if="reset"/>
        <ForgotPasswordV2 v-else-if="forgotPassword" @signIn="forgotPassword = false"/>
        <UserLogin v-else-if="!isSignUp" @switchForm="switchForm" @forgotPassword="forgotPassword = true"/>
        <UserRegister @switchForm="switchForm" v-else/>
      </div>
    </div>
    <div class="detailsContainer">
      <div v-if="!isSignUp">
        <div class="line">ENGAGE.</div>
        <div class="line">EMPOWER.</div>
        <div class="line">TRANSFORM.</div>
<!--        <div class="desc">We are lorem ipsum team dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>-->
        <div class="bottomRow">
          <div class="circles">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
          </div>
          <div class="signups" v-if="number_of_signups">
            {{number_of_signups}}+ people in your organisation have joined, now it’s your turn
          </div>
        </div>
      </div>
      <div v-else>

      </div>
    </div>
  </div>
</template>


<script>

import UserLogin from "@/components/NotLoggedIn/UserLogin.vue";
import DarkModeToggler from "@/components/Home/New/DarkModeToggler.vue";
import UserRegister from "@/components/NotLoggedIn/UserRegister.vue";
import ForgotPasswordV2 from "@/components/NotLoggedIn/ForgotPasswordV2.vue";
import ResetPasswordV2 from "@/components/NotLoggedIn/ResetPasswordV2.vue";
import {mapGetters} from "vuex";

export default {
  components: { UserLogin, DarkModeToggler, UserRegister, ForgotPasswordV2, ResetPasswordV2 },
  name: "LoginV2",
  props: ['reset'],
  data() {
    return {
      isSignUp: false,
      forgotPassword: false,
    }
  },
  mounted() {
    this.setCookie('slDomainName',window.location.hostname,20)
  },
  methods: {
    setCookie(cname, cvalue, exhrs) {
      const d = new Date();
      d.setTime(d.getTime() + (exhrs*60*1000));
      let expires = "expires="+ d.toUTCString();
      console.log(this.cookieDomain)
      document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/; domain=" + this.cookieDomain;
    },
    switchForm() {
      this.isSignUp = !this.isSignUp;
    },
  },
  computed: {
    ...mapGetters(["skinsWithDarkMode","skin","default_nav_elements","navElements","client"]),
    cookieDomain() {
      const domainName = window.location.hostname;
      let startIndex = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development' ? domainName.indexOf("spectrum") : domainName.indexOf("surge-qa");
      return domainName.substring(startIndex);
    },
    nav_elements() {
      let els = this.default_nav_elements;
      Object.entries(this.navElements).forEach(el => {
        const [key,value] = el;
        if(els.hasOwnProperty(key)) {
          els[key] = value.replace('&#39;',"'");
        }
      });
      return els;
    },
    number_of_signups() {
      if(this.client.number_of_signups < 100) return 0;
      const numOfZeros = this.client.number_of_signups.toString().length - 1;
      return Math.floor(this.client.number_of_signups / Math.pow(10,numOfZeros)) * Math.pow(10,numOfZeros);
    },
    darkModeAvailable() {
      return this.skinsWithDarkMode.includes(this.skin)
    },
  }
};

</script>



<style lang="less">
#LoginV2 {
  overflow: hidden;
  width: 100vw;
  position: relative;
  height: 100vh !important;
  .loginDarkModeContainer {
    position: absolute;
    z-index: 9999;
    top: 10px;
    right: 50px;
    color: white;
  }
  .imageContainer{
    background-image: url("https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/ds.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    background-position: center;
    height: 110%;
    width: 135%;
    margin-right: -30%;
    margin-bottom: -20%;
    z-index: 1;
  }
  .loginContainer {
    position: absolute;
    border-radius: 30px;
    top: 5%;
    left: 4%;
    width: 45%;
    min-width: 500px;
    max-width: 650px;
    height: 90%;
    background-color: white;
    padding: 20px 5px;
    z-index: 3;
    overflow: hidden;
    .innerLoginContainer {
      height: 100%;
      width: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
        border-radius: 10px;
      }
    }
  }
  .detailsContainer {
    position: absolute;
    bottom: 0;
    left: 55%;
    width: calc(45%);
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    z-index: 2;
    height: 50%;
    color: white;
    .line {
      font-size: 4rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
    .desc {
      font-size: 2rem;
      line-height: 2.25rem;
    }
    .bottomRow {
      width: 100%;
      margin-top: 40px;
      display: grid;
      grid-template-columns: fit-content(100%) 1fr;
    }
    .circles {
      width: 150px;
      position: relative;
      .circle {
        width: 50px;
        height: 50px;
        border: white 1px solid;
        background-color: #ED3A75;
        border-radius: 50%;
        position: absolute;
      }
      :nth-child(1) {
        left: 0;
      }
      :nth-child(2) {
        left: 35px;
      }
      :nth-child(3) {
        left: 70px;
      }
    }
    .signups {
      font-size: 1.3rem;
    }
  }
  @media screen and (min-height: 750px) {
    .loginContainer {
      height: calc(100% - 55px);
      top: 50px;
    }

  }
  @media screen and (max-width: 1000px) {
    .detailsContainer { display: none !important}
  }
  @media screen and (max-width: 767px) {
    .loginContainer {
      left: 10px;
      width: calc(100% - 20px);
      min-width: calc(100% - 20px);
      #UserRegister, #UserLogin {
        padding: 30px 25px;
      }
    }
  }
}
</style>