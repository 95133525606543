<template>
  <div id="TherapyNDLandingPage">
    <div class="bannerOuter" v-if="showBanner && !loading">
      <div id="banner">
        <div class="backgroundImage"></div>
        <div class="textContainer">
          <div class="innerTextContainer p-5">
            <h3>Welcome to</h3>
            <h2>{{ hubName }}</h2>
            <p>Our platform offers convenient access to psychotherapy, psychology services, and assessments for ADHD and Autism. With a focus on comprehensive care, our digital approach ensures accessible and personalised support for individuals of all ages. Explore our services to embark on a journey towards improved mental health and well-being.</p>
<!--            <button class="therapyBtn mt-5 no-border" @click="$router.push('service-dashboard/cmh_assessment')">Start Now</button>-->
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="content-container">
        <div v-if="services.length > 0" class="m-5">
          <div class="pickupTagLine">Pick up where you left off...</div>
          <TherapyServicesTable :can_access_nd_forms="can_access_nd_forms" :services="services" :forms="forms" :is_school_questionnaire_complete="is_school_questionnaire_complete"/>
        </div>
        <div id="bannerSub" class="m-5 pl-5">
<!--          <h5>Available Services</h5>-->
<!--          <p>Here you can find all of the services that you have been referred to avail of. If this is your first time here, you will only see the <span>Specialist Assessment</span>. The <span>specialist assessment</span> will help us to determine:</p>-->
<!--          <ul>-->
<!--            <li>Emotional and well-being needs</li>-->
<!--            <li>Assessments of neurodevelopmental differences and neurodivergence (such as autism and ADHD)</li>-->
<!--            <li>Psychological support people experiencing mild to moderate emotional and behavioural challenges.</li>-->
<!--          </ul>-->
          <p class="bottomPara">*If you have been referred to a service, and you do not see that service listed below. Then you can <span @click="showCodeInput = true">click here to enter a Therapy Referral Code</span></p>

          <div v-if="showCodeInput">
            <div class="referralCodeBox" v-if="showCodeInput">

              <b-form-group id="rc-gp" label="Therapy Referral Code" label-for="rc-input">
                <b-form-input id="rc-input" v-model="therapyCodeInput"/>
                <div class="closeBtn" @click="showCodeInput = false">X</div>
              </b-form-group>
              <button class="therapyBtn2 position-relative" @click="submitReferralCode">
                <span v-if="loadingCode" class="button-loading"></span>
                <span v-else>Save</span>
              </button>
            </div>
            <div v-if="therapyCodeError" class="therapyCodeError">
              {{therapyCodeError}}
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="beigeSection pt-5" v-if="filtered_available_services.length">
      <div class="content-container">
        <div class="cardHolder" v-if="!loading">
          <div class="cardOuter p-5" v-for="service in filtered_available_services">
            <ServiceCard :currency="service.currency" :price="service.price" :name="service.label" :slug="service.global_event_category"/>
          </div>
        </div>
      </div>
      <div ref="backBtm"></div>
    </div>
  </div>
</template>

<script>
import TherapyTextQuestion from "@/components/TherapyPortal/Questions/TherapyTextQuestion.vue";
import ServiceCard from "@/components/TherapyPortal/Misc/ServiceCard.vue";
import TherapyServicesTable from "@/components/TherapyPortal/TherapyServicesTable.vue";
import {setTags} from "@sentry/vue";

export default {
  name: "TherapyNDLandingPage",
  components: {TherapyServicesTable, ServiceCard, TherapyTextQuestion},
  data() {
    return {
      showCodeInput: false,
      therapyCodeInput: '',
      services: [],
      available_services: [],
      forms: [],
      is_school_questionnaire_complete: false,
      loading: true,
      can_access_nd_forms: false,
      loadingCode: false,
      therapyCodeError: '',
      hubName: 'Digital Therapy Hub'
    }
  },
  mounted() {
    this.getServices();
    this.getAvailableServices();
    const navItems = this.$store.getters.nav.concat(this.$store.getters.sideNav);
    navItems.forEach(navItem => {
      if(navItem.hasOwnProperty('sub_items')) {
        navItem.sub_items.forEach(sub => {
          if(sub.item_slug === 'cmh') this.hubName = sub.label
        })
      } else {
        if(navItem.item_slug === 'cmh') {
          this.hubName = navItem.label
        }
      }
    })
  },
  computed: {
    showBanner() {
      return true;
      // if(!this.services.length) return true;
      // let displayBanner = true;
      // this.services.forEach(svc => {
      //   if(svc.appointments.past.length || svc.appointments.upcoming.length) displayBanner = false;
      // })
      // return displayBanner;
    },
    filtered_available_services() {
      const globalEventCategories = this.services.map(item => item.global_event_category);
      return this.available_services.filter(svc => !globalEventCategories.includes(svc.global_event_category))
    }
  },
  watch: {
    showCodeInput(val) {
      if(val) this.m('enter-referral-code-clicked')
    }
  },
  methods: {
    async getAllForms() {
      let params = {
        path: 'api/u/cmh/get-intake-forms',
      }
      const res = await this.api(params)
      if(res.success) {
        this.forms = res.data
      }
      const initialAssessment = await this.api({path: 'api/u/cmh/can-access-nd-forms'});
      if(initialAssessment.success) {
        this.is_school_questionnaire_complete = initialAssessment.school_form_complete;
        this.can_access_nd_forms = initialAssessment.can_access_nd_forms;
      }
      this.loading = false;
    },
    async submitReferralCode() {
      this.m('referral-code-submitted',{code: this.therapyCodeInput});
      this.loadingCode = true;
      const res = await this.api({
        path: "api/u/cmh/redeem-referral-code",
        referral_code: this.therapyCodeInput,
      })
      if(res.success) {
        this.m('referral-code-success',{code: this.therapyCodeInput, service: res.service.area});
        await this.getAvailableServices();
        this.$refs.backBtm.scrollIntoView({ behavior: 'smooth' });
      } else {
        this.therapyCodeError = res.error;
        const alreadyRedeemed = res.error.includes('already been redeemed');
        this.m(alreadyRedeemed ? 'referral-code-already-redeemed':'referral-code-service-not-found',{code: this.therapyCodeInput});
        setTimeout(()=> {this.therapyCodeError = ''},5000)
      }
      this.loadingCode = false;
    },
    async getServices() {
      const res = await this.api({
        path: "api/u/cmh/get-services-in-progress"
      });
      this.services = res.data;
      await this.getAllForms();
    },
    async getAvailableServices() {
      const res = await this.api({
        path: 'api/u/cmh/get-all-services'
      })
      if(res.specialist_assessment_active === 1) {
        this.available_services = res.data
      } else {
        const svcs = res.data.filter(svc => svc.global_event_category !== 'cmh_assessment');
        this.available_services = svcs;
      }
    }
  }
}
</script>

<style lang="less">
#TherapyNDLandingPage {
  .pickupTagLine {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #3A4A61;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
  }
  .beigeSection {
    background-color: #F8F8F8;
  }
  .cardHolder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    .cardOuter {

    }
  }
  .bannerOuter {
    background-color: black;
  }
  #banner {
    width: 100%;
    margin: auto;
    height: 528px;
    position: relative;
    .textContainer {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, #000000 58%, rgba(0, 0, 0, 0) 90%);
      .innerTextContainer {
        width: 55%;
        float: right;
        height: 100%;
        h3 {
          color: white;
          font-family: DMSans, sans-serif;
          font-size: 2.5rem;
          font-weight: 700;
          letter-spacing: 0.2rem !important;
          padding: 0 !important;
          margin-top: 10%;
        }
        h2 {
          color: white;
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
          font-size: 3rem;
          letter-spacing: 0.25rem !important;
          text-align: left;
        }
        p {
          color: white !important;
        }

      }
    }
    .backgroundImage {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 55%;
      min-height: 100%;
      background-image: url("/img/therapy/adult-woman-in-a-psychology-studio-2023-11-27-05-04-04-utc 1.png");
      background-size:cover;
    }
  }
  #bannerSub {
    color: #3A4A61 !important;
    h5 {
      font-size: 2rem !important;
    }
    p {
      max-width: 100% !important;
      span {
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      }
    }
    .bottomPara {
      max-width: 100% !important;
      span {
        font-family: DMSans, sans-serif;
        text-decoration: underline;
        cursor: pointer;
        color: #D60570;
      }
    }
  }
  .referralCodeBox {
    display: flex;
    justify-content: center;
    align-items: flex-end;


    .form-group {
      .closeBtn {
        position: absolute;
        top: 0;
        left: -25px;
        font-size: 1.75rem;
        font-weight: bold;
        color: #D60570;
        cursor: pointer;
      }
      position: relative;
      margin-bottom: 0!important;
    }
    label {
      font-family: DMSans, sans-serif !important;
      font-size: 1.35rem !important;
    }
    button {
      width: 160px !important;
      height: 47px !important;
      margin-left: 20px !important;
      margin-bottom: 5px !important;
    }
    input {
      background: #EEEEF3;
      border: 1px solid #D0D5DA;
      width: 430px !important;
      max-width: 430px !important;
      height: 55px !important;
      border-radius: 40px !important;
      padding-left: 20px !important;
      font-size: 2rem !important;
    }

  }
  .therapyCodeError {
    font-size: 2rem;
    color: darkred;
    text-align: center;
  }
  @media only screen and (max-width: 800px) {
    .textContainer {
      background: transparent !important;
    }
    .backgroundImage {
      width: 100% !important;
      height: 100% !important;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        opacity: .65;
      }
    }
    .innerTextContainer {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media only screen and (max-width: 992px) {
    .cardHolder {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 768px) {
    .referralCodeBox {
      flex-direction: column;
      align-items: center;
      .form-group {
        margin-bottom: 20px !important;
      }
    }
  }

}
</style>