<template>
  <div id="SearchBarResult" >
    <div class="imgHolder img-fluid" v-bind:style="{ backgroundImage: 'url(' + thumbnail + ')' }" @click="goToContent">
    </div>
    <div class="resultHeader" @click="goToContent">
      {{result.title}}
      <span v-if="isWellbeingPlus" class="ml-2">Wellbeing<sup>+</sup></span>
    </div>
    <div class="resultSubTitle" @click="goToContent">
      {{ parseType }} <span v-if="result.subtitle" class="ml-2">{{result.subtitle}}</span>
    </div>
    <div class="resultMeta">
      <div class="duration" v-if="parseDuration" @click="goToContent">{{parseDuration}}</div>
      <div class="course" v-if="parseNumberOfParts" @click="goToContent">{{parseNumberOfParts}}</div>
<!--      <div class="tag ml-3" v-for="tag in tags" @click="newSearch(tag)">{{'#' + tag}}</div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBarResult",
  props: ['result'],
  data() {
    return {

    }
  },
  methods: {
    getThumbnail() {
      if(this.result.thumbnail) return this.video.thumbnail;
      if(this.video.schedule_type === 'studio') return '/img/wellbeing-studio/wellbeing-studio.svg';
      return '/img/wellbeing-studio/digital-gym.svg';
    },
    newSearch(term) {
      this.$emit('newSearch',term)
    },
    goToContent() {
      console.log(this.result.type)
      switch (this.result.type) {
        case 'gym':
          this.$router.push('/digital-gym/' + this.result.id + '?back=home');
          break;
        case 'studio':
          this.$router.push('/wellbeing-studio/' + this.result.id + '?back=home');
           break;
        case 'fitness_plans': case 'training_class':
          this.$router.push('/digital-gym/training-plans/' + this.result.id + '?back=home');
          break;
        case 'wellbeing_courses':
          if(this.result.details.channel === "be_calm") {
            this.$router.push('/be-calm/' + this.result.id + '?back=home');
          } else {
            this.$router.push('/wellbeing/' + this.result.id + '?back=home');
          }
          break;
        case 'podcasts':
          this.$router.push('/sound-space/' + this.result.id + '?play=' + this.result.details.last_epidsode_id +'&back=home');
          break;
      }
      this.$emit('resultSelected')
    },
    getNumber(num) {
      if(Number.parseInt(num) > 9) return num;
      switch(Number.parseInt(num)) {
        case 0: return null;
        case 1: return "one";
        case 2: return "two";
        case 3: return "three";
        case 4: return "four";
        case 5: return "five";
        case 6: return "six";
        case 7: return "seven";
        case 8: return "eight";
        case 9: return "nine";
        default: return null;
      }
    }
  },
  computed: {
    thumbnail() {
      if(this.result.thumbnail) return this.result.thumbnail;
      switch (this.result.type) {
        case 'gym': return '/img/wellbeing-studio/digital-gym.svg';
        case 'studio': return '/img/wellbeing-studio/wellbeing-studio.svg';
        case 'fitness_plans': return '/img/wellbeing-studio/digital-gym.svg';
        case 'wellbeing_courses':
          if(this.result.details.channel === "be_calm") return "Be Calm";
          return "Wellbeing Course"
        case 'podcasts': return "Podcast"
      }
    },
    tags() {
      const tagsToExclude = ["surge"];
      return this.result.tags.filter(tag => !tagsToExclude.includes(tag.toLowerCase()));
    },
    parseNumberOfParts() {
      switch (this.result.type) {
        case 'wellbeing_courses':
          const num = this.getNumber(this.result.details.total_modules);
          if(num === null) return null;
          return `A ${num} part course`;
        default:
          return null;
      }
    },
    parseType() {
      switch (this.result.type) {
        case 'gym': return "Digital Gym";
        case 'studio': return "Wellbeing Studio";
        case 'fitness_plans': return "Fitness Plan";
        case 'wellbeing_courses':
          if(this.result.details.channel === "be_calm") return "Be Calm";
          return "Wellbeing Course"
        case 'podcasts': return "Podcast"
      }
    },
    parseDuration() {
      switch (this.result.type) {
        case 'gym': return "30:00";
        case 'studio': return "30:00";
        case 'fitness_plans': return null;
        case 'wellbeing_courses': return null
        case 'podcasts': return this.result.details.duration;
        default: return null;
      }
    },
    isWellbeingPlus() {
      return this.result.type === 'gym' || this.result.type === 'studio'
    }
  }
}
</script>

<style lang="less">
#SearchBarResult {
  width: 100%;
  height: 100px;
  display: grid;
  padding: 5px;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr 1fr 1fr;
  border-bottom: #dedede 1px solid;
  &:hover {
    background-color: #f1efef;
  }
  .imgHolder {
    grid-column: 1;
    grid-row: 1 / span 3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: #e0dede;
  }
  .resultHeader {
    grid-column: 2;
    grid-row: 1;
    color: black;
    font-size: 1.6rem;
    cursor: pointer;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    margin-top: 10px;
    margin-left: 10px;
    span {
      font-size: 1.4rem;
      color: #00a4a9 !important;
    }
  }
  .resultSubTitle {
    grid-column: 2;
    grid-row: 2;
    color: black;
    margin-left: 10px;
    font-size: 1.3rem;
    cursor: pointer;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    span {
      font-family: DMSans, Arial, Helvetica, sans-serif;
      font-size: 1.1rem;
    }
  }
  .resultMeta {
    font-size: 1.2rem;
    color: black;
    margin-left: 10px;
    align-items: center;
    display: flex;
    .duration {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      padding: 1px 5px;
      border: 1px black solid;
      background-color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }
    .course {
      font-size: 1.3rem;
      cursor: pointer;
    }
    .tag {
      cursor: pointer;
      padding: 2px 4px;
      border-radius: 10px;
    }
    .tag:hover {
      background-color: grey;
      color: white;
    }
  }
}
</style>