import Vue from "vue";
import moment from "moment";
import store from '@/store'
import ConversationWrapper from '../components/TotalMentalHealth/ConversationWrapper.vue'
import EvolveSingleCoach from '../components/TotalMentalHealth/EvolveSingleCoach.vue'
import DigitalClinicsSingleClinician from '../components/DigitalClinics/DigitalClinicsSingleClinician.vue'
import CardPayment from "../components/Stripe/CardPayment";
import RedeemVoucher from "../components/Stripe/RedeemVoucher";

import ProfileQuestionnaireModal from '../components/modals/ProfileQuestionnaireModal.vue'
import forms from "../assets/therapyForms.json"

import axios from "axios";
import config from "../config/constants";

/**
 * https://bootstrap-vue.js.org/docs/components/modal/#modal-message-boxes
 * accepted props : https://bootstrap-vue.js.org/docs/components/modal/#comp-ref-b-modal
 * but in camelCase and not kebab-case
 */
const defaultModalOptions = {
  title: "",
  hideHeader: true,
  size: "md",
  buttonSize: "lg",
  okTitle: "OK",
  cancelTitle: "NO",
  footerClass: "p-2",
  hideHeaderClose: true,
  centered: true,
  contentClass: "pop-up-content"
};
/**
 *  language imports for translation files
 */
import * as en from '../assets/lang/_en'
import * as de from '../assets/lang/_de'
import { options } from "less";
import AddCard from "../components/Stripe/AddCard";
const languages = {
  en: en,
  de: de
}
const languageNames = {
  en: 'English',
  de: 'Deutsch'
}
Vue.mixin({
  methods: {
    async logAction(action, successFunction) {
      let res = await this.api({
        path: "api/u/settings/log-action",
        action,
      });
      if(successFunction){
        successFunction;
      }
    },
    scrollToRef(ref) {
      if(ref){
        const el = this.$refs[ref];
        el.scrollIntoView({ behavior: "smooth" });
      }else{
        const el = document.getElementById('LoggedIn');
        el.scrollIntoView({behavior: "smooth"});
      }
    },
    friendlyTimeString(str) {
      return moment(str).fromNow();
    },
    getDateHours(str) {
      //return this.$date(new Date(str.replace(" ", "T")), "ddd DD MMM HH.mm");
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("ddd DD MMM HH:mm");
    },
    getMonth(str) {
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("MMM");
    },
    getDay(str) {
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("DD");
    },
    getDayString(str) {
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("ddd");
    },
    getDayStringLong(str) {
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("dddd");
    },
    getDate(str) {
      return moment(str).locale(this.$store.state.lang).format("ddd DD MMM Y");
    },
    getDateWithoutYear(str) {
      return moment(str).locale(this.$store.state.lang).format("ddd DD MMM");
    },
    getShortDate(str) {
      return moment(str).locale(this.$store.state.lang).format("DD MMM YY");
    },
    getHoursMinutes(str, isTwelveHour = false) {
      if (isTwelveHour) return moment(str.replace(" ", "T")).format("h:mm A");
      return moment(str.replace(" ", "T")).format("HH:mm");
    },
    getFormatedDate(date, format) {
      return moment(date).locale(this.$store.state.lang).format(format)
    },
    getChatDate(date) {
      return moment(date).locale(this.$store.state.lang).format('DD/MM HH:mm');
    },
    getDateLineText(appointmentStr) {
      let appointmentDate = new Date(appointmentStr).setHours(0, 0, 0, 0);
      if (navigator.userAgent.toLowerCase().indexOf("safari/")) {
        const safariDate = new Date(appointmentStr.replace(/-/g, "/"));
        appointmentDate = new Date(safariDate).setHours(0, 0, 0, 0);
      }
      const today = new Date().setHours(0, 0, 0, 0);
      const dayString = moment(appointmentDate)
        .locale(this.$store.state.lang)
        .format("dddd");
      if (appointmentDate === today) return "Today";
      if (appointmentDate === today + 86400000) return "Tomorrow";
      if (moment(appointmentDate).isoWeek() === moment().isoWeek())
        return dayString + " - This Week";
      if (moment(appointmentDate).isoWeek() === moment().isoWeek() + 1)
        return dayString + " - Next Week";
      if (moment(appointmentDate).isoWeek() === 1 && moment().isoWeek() === 52)
        return dayString + " - Next Week";
      return moment(appointmentDate).fromNow();
    },
    getFriendlyDateText(appointmentStr) {
      let appointmentDate = new Date(appointmentStr).setHours(0, 0, 0, 0);
      if (navigator.userAgent.toLowerCase().indexOf("safari/")) {
        const safariDate = new Date(appointmentStr.replace(/-/g, "/"));
        appointmentDate = new Date(safariDate).setHours(0, 0, 0, 0);
      }
      const today = new Date().setHours(0, 0, 0, 0);
      const dayString = moment(appointmentDate)
        .locale(this.$store.state.lang)
        .format("Do MMMM");
      if (appointmentDate === today) return "Today";
      if (appointmentDate === today + 86400000) return "Tomorrow";
      if (moment(appointmentDate).isoWeek() === moment().isoWeek())
        return dayString;
      if (moment(appointmentDate).isoWeek() === moment().isoWeek() + 1)
        return dayString;
      if (moment(appointmentDate).isoWeek() === 1 && moment().isoWeek() === 52)
        return dayString; 
      return moment(appointmentDate).fromNow();
    },
    getTheme() {
      return "theme-" + this.$store.getters.skin
    },
    isPast(str) {
      return new Date(str.replace(" ", "T")) < new Date();
    },
    showMsgModal(msg = "something", options = defaultModalOptions) {
      const skin = this.$store.getters.skin;
      options.modalClass = "theme-" + skin;
      return this.$bvModal.msgBoxOk(msg, options);
    },
    showConfirmModal(msg = "something", options = defaultModalOptions) {
      const skin = this.$store.getters.skin;
      options.modalClass = "theme-" + skin;
      return this.$bvModal.msgBoxConfirm(msg, options);
    },
    showConversationWrapper() {
      const content = this.$createElement(ConversationWrapper)
      const skin = this.$store.getters.skin;
      options.modalClass = "conversation-modal theme-" + skin;
      options.okTitle = "Close";
      let opts = {
        title: "",
        hideHeader: true,
        size: "xl",
        buttonSize: "lg",
        okTitle: "OK",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
        contentClass: "pop-up-content"
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    coachModal(c_id) {
      const content = this.$createElement(EvolveSingleCoach, { props: { coach_id: c_id } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "Choose your coach",
        hideHeader: false,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass: "coach_modal_header bg-brand-highlight-1",
        size: "xl",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: 'single_coach_modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    cardModal(amount, currency) {
      const content = this.$createElement(CardPayment, { props: { amount: amount, currency: currency } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "Payment",
        hideHeader: false,
        hideFooter: true,
        modalClass: "card_modal theme-" + skin,
        headerClass: "card_modal_header bg-brand-highlight-1",
        size: "xl",
        buttonSize: "sm",
        centered: true,
        contentClass: "card_modal",
        id: 'card_modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    voucherModal() {
      const content = this.$createElement(RedeemVoucher, { props: { close_function: () => { this.$bvModal.hide("redeem_voucher_modal") } } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "Payment",
        hideHeader: false,
        hideFooter: true,
        modalClass: "redeem_voucher_modal wallet_modal theme-" + skin,
        headerClass: "redeem_voucher_modal_header d-none",
        footerClass: "d-none",
        size: "md",
        buttonSize: "sm",
        centered: true,
        contentClass: "card_modal",
        id: 'redeem_voucher_modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    showProfileQuestionnaireModal(user, email, client_name) {
      const content = this.$createElement(ProfileQuestionnaireModal, { props: { user: user, email: email, client_name: client_name } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "Complete your profile to start your journey!",
        hideHeader: true,
        hideFooter: true,
        hideOk: true,
        modalClass: "questionnaire-modal theme-" + skin,
        headerClass: "questionnaire-modal-header",
        size: "lg",
        buttonSize: "sm",
        centered: true,
        contentClass: "questionnaire-modal-content",
        id: 'profile-questionnaire-modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    currencyString(cents = 0, curr = "eur") {
      return (cents /= 100).toLocaleString("en-GB", {
        style: "currency",
        currency: curr.toUpperCase()
      });
    },
    async api(params) {
      if(!params.hasOwnProperty('user_id')) {
          params.user_id = this.$store.getters.user_id
      }
      const path = params.path
      let payload = params
      delete payload.path
      let res = await axios.post(
        config.api_env + "/application/" + path,
        payload,
        config.options
      );
      if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => { });
        return false;
      } else {
        return res.data;
      }
    },
    isLayaWellbeing() {
        console.log(window.location.host)
      return window.location.host.search('laya-wellbeing') > -1
    },
    isConnectedMentalHealth() {
        return false;
    },
    isOver18(dateOfBirth) {
        let dobMoment = moment(dateOfBirth, 'DD-MM-YYYY', true);
        if (!dobMoment.isValid()) {
          dobMoment = moment(dateOfBirth, 'D-M-YYYY', true);
        }
        if (!dobMoment.isValid()) {
          return false;
        }
        const age = moment().diff(dobMoment, 'years');
        return age >= 18;
    },
    connectedMentalHealthType() {
        if(this.$store.getters.user.therapy.area) return this.$store.getters.user.therapy.area.toLowerCase();
        if(!this.isConnectedMentalHealth()) return null;
        const parts = window.location.host.split('.');
        return parts[0];
    },
    genRandonString(length) {
       var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
       var charLength = chars.length;
       var result = '';
       for ( var i = 0; i < length; i++ ) {
          result += chars.charAt(Math.floor(Math.random() * charLength));
       }
       return result;
    },
    getCurrencyPriceString(currency, amount, full = false) {
      let am = amount / 100
      let opts = {
        style: "currency",
        currency: currency.toUpperCase(),
        maximumFractionDigits: full ? 2 : 0
      }
      let value = am.toLocaleString("en-IE", opts);
      if (value.slice(-3) === '.00'){
        value = value.slice(0, value.length-3);
      }
      return value;
    },
    addCommasToInteger(int) {
        let number = Number.parseInt(int);
        if(isNaN(number)) return false;
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    convertMilesToMeters(miles) {
        return Math.floor(miles * 1609.344);
    },
    convertMetersToMiles(meters) {
        return Math.round(meters * 0.000621371 * 100) / 100;
    },
    determinePercentageOfFormComplete(formName,answers) {
            const form = forms[formName];
            let questionCount = 0;
            let answerCount = 0;
            if(formName.substring(0,6) === 'intake') {
                form.form_groups.forEach(group => {
                            group.questions.forEach(q => {
                                if(q.required) {
                                  questionCount++;
                                  if(answers.hasOwnProperty(q.id)) {
                                     if(q.type === 'phone') {
                                         if(answers[q.id].phone) answerCount++;
                                     } else {
                                         if(answers[q.id]) answerCount++;
                                     }
                                  }
                                }

                            })
                        });
            } else {
                if(form) {
                    form.questionnaire.forEach(q => {
                        questionCount++;
                        const i = answers.findIndex(a => a.id === q.id);
                        if(i !== -1 && answers[i].answer !== null) answerCount++
                    });
                } else {
                    console.log(formName)
                }
            }
            return answerCount / questionCount;
        },
    validateEmailAddress(email) {
      return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    },
    m(eventName,payload) {
        //console.log({"event": eventName, path: this.$route.path ,...payload});
        //window._mtm.push({"event": eventName, path: this.$route.path ,...payload});
    },
    getTherapyFromSlug(slug) {
        let result = {};
        switch (slug) {
            case 'therapy_child':
            case 'psychology_child':
                result.age_group = 'Child';
                result.area = 'Therapy';
                break;
            case 'autism_child':
            case 'adhd_child':
            case 'adhd_and_autism_child':
                result.age_group = 'Child';
                result.area = 'Neurodiversity';
                break;
            case 'therapy_adult':
            case 'psychology_adult':
                result.age_group = 'Adult';
                result.area = 'Therapy';
                break;
            case 'autism_adult':
            case 'adhd_adult':
            case 'adhd_and_autism_adult':
                result.age_group = 'Adult';
                result.area = 'Neurodiversity';
                break;
            default:
                result.age_group = 'Adult';
                result.area = 'Therapy';
        }
        return result;
    },
    getTherapyDetailsFromSlug(slug) {
            switch (slug) {
                case 'therapy_child':
                    return {
                        label: 'Child & Adolescent Psychotherapy',
                        price: '8500',
                        area: "Therapy",
                        age_group: "Child"
                    }
                case 'psychology_child':
                    return {
                        label: 'Child & Adolescent Psychology',
                        price: '8500',
                        area: "Therapy",
                        age_group: "Child"
                    }
                case 'autism_child':
                    return {
                        label: 'Online Autism Assessment - Child and Adolescent',
                        price: '200000',
                        area: "Neurodiversity",
                        age_group: "Child"
                    }
                case 'adhd_child':
                    return {
                        label: 'Online ADHD Assessment - Child and Adolescent',
                        price: '200000',
                        area: "Neurodiversity",
                        age_group: "Child"
                    }
                case 'adhd_and_autism_child':
                    return {
                        label: 'Online ADHD & Autism Assessment - Child & Adolescent',
                        price: '200000',
                        area: "Neurodiversity",
                        age_group: "Child"
                    }
                case 'therapy_adult':
                    return {
                        label: 'Adult Digital Psychotherapy',
                        price: '8500',
                        area: "Therapy",
                        age_group: "Adult"
                    }
                case 'psychology_adult':
                    return {
                        label: 'Adult Digital Psychology',
                        price: '8500',
                        area: "Therapy",
                        age_group: "Adult"
                    }
                case 'autism_adult':
                    return {
                        label: 'Online Autism Assessment - Adult',
                        price: '200000',
                        area: "Neurodiversity",
                        age_group: "Adult"
                    }
                case 'adhd_adult':
                    return {
                        label: 'Online ADHD Assessment - Adult',
                        price: '200000',
                        area: "Neurodiversity",
                        age_group: "Adult"
                    }
                case 'adhd_and_autism_adult':
                    return {
                        label: 'Online ADHD & Autism Assessment - Adult',
                        price: '200000',
                        area: "Neurodiversity",
                        age_group: "Adult"

                    }
                case 'cmh_assessment':
                    return {
                        label: 'Specialist Assessment',
                        price: '0',
                        area: "Therapy",
                        age_group: "Adult"
                    }
            }
        },
    cmhAdditionalFormNames(age_group,age = 0) {
          const formNames = age_group === 'Adult' ?
              ['asrs_neurodiversity_adult','aq-10_neurodiversity_adult']:
              ['rcads_neurodiversity_child_p','snap-iv_neurodiversity_child_p','rcads_neurodiversity_child_c','yp-core_neurodiversity_child_c'];
          if(age_group === 'Child') {
            if(age <= 12) {
              formNames.push('aq10-4-to-11_neurodiversity_child_p');
            } else if(age < 16) {
              formNames.push('aq10-12-to-15_neurodiversity_child_p')
            } else {
              formNames.push('aq10-16+_neurodiversity_child_p')
            }
          }
          return formNames;
    },
    isAccessibilitySite() {
      const parsedUrl = new URL(window.location.href);
      let hostnameParts = parsedUrl.hostname.split('.');
      return hostnameParts[0] === 'accessibility';
    }
  },
  computed: {
    allLanguages() {
      return languageNames
    },
    getText() {
      let lang = store.state.lang;
      if(lang !== 'en' || lang !== 'de') lang = 'en';
      return languages[lang].default
    }
  }
});
