<template>
    <div class="HomeCardCarousel py-3" v-if="!loaded || content.length > 0" @mouseover="showArrows = true" @mouseleave="showArrows = false" :class="showArrows ? null : 'hide-arrows'">
      <div v-if="!loaded" class="mb-3 container d-flex flex-row justify-content-between align-items-center">
        <HomeLoading/>
      </div>
        <div v-else>
          <div class="mb-3 container d-flex flex-row justify-content-between align-items-center">
              <div class="d-flex align-items-center mb-3">
                  <h2 class="home-title mb-0 mr-5">{{ title }}</h2>
                  <div class="see-more-link brand-text" @click="seeMore">
                      See More <i class="fa-solid fa-chevron-right"></i>
                  </div>
              </div>
          </div>
          <div class="position-relative">
              <div class="pb-5 container" v-if="type === 'featured-event' && loaded === true" :class="type === 'featured-event'? 'banner-card-container':null">
                  <slick id='slick-id' ref="slick" :options="eventSlickOptions" @afterChange="handleSwipe" @beforeChange="handleBeforeChange"
                      class="justify-content-start">
                      <HomeBannerEventCard :event="event" :key="i" v-for="(event, i) in content" :swiping="swiping" :reminders="reminders"/>
                  </slick>
              </div>
              <div class="pb-5 container" v-else-if="loaded === true">
                  <slick id='slick-id' ref="slick" :options="slickOptions" @afterChange="handleSwipe" @beforeChange="handleBeforeChange"
                      class="justify-content-start" >
  <!--                    <div :key="i" v-for="(item, i) in content" @click="cardLink(item)">-->
                      <div :key="i" v-for="(item, i) in content">
                          <HomeRecipeCard :item="item" :type="type" v-if="type.includes('recipe')" :swiping="swiping"></HomeRecipeCard>
                          <HomeClinicCard :item="item" v-else-if="type === 'clinic'" type="clinic" :swiping="swiping" :settings="clinicSettings"></HomeClinicCard>
                          <VimeoCard :video="item" :type="type" v-else-if="isVimeoVideo(item,type)" :swiping="swiping" :perma-tag="permaTag" :reminders="reminders" :unique_id="genRandonString(10)"></VimeoCard>
                          <CourseCard :item="item" :type="type" v-else-if="item.source === 'ResCourses'" :swiping="swiping" />
                          <VideoCard :video="item" :type="type" v-else-if="item.media === 'video'" :swiping="swiping"/>
                          <PodcastCard :podcast="item" :type="type" v-else-if="item.media === 'audio'" :swiping="swiping" :unique_id="genRandonString(10)"/>
                          <PodcastCard :podcast="item" :type="type" v-else-if="item.media === 'text'" :swiping="swiping" :unique_id="genRandonString(10)"/>
                          <PodcastCard :podcast="item" :type="type" v-else-if="item.media === 'podcasts'" :swiping="swiping" :unique_id="genRandonString(10)"/>

                      </div>
                      <div class="d-flex justify-content-start align-items-center" :class="{hideLoader: !loading_new_content}" v-if="page <= totalPages">
                        <Loader v-if="loading_new_content && type !== 'recipes'"/>
                      </div>
                  </slick>
              </div>
              <div class="d-flex flex-row flex-nowrap video-blur-container" v-if="!isAccessibilitySite()">
                  <div class="left-blur"></div>
                  <div class="container w-100"></div>
                  <div class="right-blur"></div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import slick from "vue-slick"
import VideoCard from "@/components/Home/New/VideoCard.vue"
import BookingCard from "@/components/Home/New/BookingCard.vue"
import PodcastCard from "@/components/Home/New/PodcastCard.vue"
import HomeBannerEventCard from "@/components/Home/New/HomeBannerEventCard.vue";
import EventBus from "@/helpers/eventbus";
import HomeRecipeCard from "@/components/Home/New/HomeRecipeCard.vue";
import Loader from "@/components/Loader.vue";
import VimeoCard from "@/components/Home/New/VimeoCard.vue";
import CourseCard from "@/components/Home/New/CourseCard.vue";
import HomeLoading from "@/components/Home/New/HomeLoading.vue";
import HomeClinicCard from "@/components/Home/New/HomeClinicCard.vue";
import config from "@/config/constants";

export default {
    name: "HomeCardCarousel",
    props: {
        title: String,
        type: String,
        permaTag: String,
        upcoming: {
          type: Boolean,
          default: false
        }
    },
    components: {
        HomeLoading,
        HomeClinicCard,
        HomeRecipeCard,
        VideoCard,
        BookingCard,
        PodcastCard,
        HomeBannerEventCard,
        slick,
        Loader,
        VimeoCard,
        CourseCard
    },
    data() {
        return {
            reminders: [],
            swiping: false,
            loaded: false,
            loading_new_content: false,
            content: [],
            page: 1,
            totalRecords: 0,
            totalPages: 0,
            optionalTag: [],
            showArrows: false,
            clinicSettings: null,
            slickOptions: {
                initialSlide: 0,
                arrows: true,
                slidesToScroll: 1,
                slidesToShow: 5,
                nextArrow:
                    '<div class="slick-arrow-next std-arrow">' +
                    '<a class="slick-arrow-container std-arrow-container"><i class="fas fa-chevron-right text-white fa-4xs"></i></a>' +
                    '</div>',
                prevArrow:
                    '<div class="slick-arrow-prev std-arrow">' +
                    '<a class="slick-arrow-container std-arrow-container"><i class="fas fa-chevron-left text-white fa-4xs"></i></a>' +
                    '</div>',
                adaptiveHeight: true,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                    {
                      breakpoint: 951,
                      settings: {
                        slidesToShow: 2,
                    },
                  },
                    {
                        breakpoint: 1066,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 1315,
                        settings: {
                            slidesToShow: 4
                        }
                    }
                ],
            },
            eventSlickOptions: {
              initialSlide: 0,
              arrows: true,
              slidesToScroll: 1,
              slidesToShow: 1,
              nextArrow:
                  '<div class="slick-arrow-next">' +
                  '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-right text-white fa-4xs"></i></a>' +
                  '</div>',
              prevArrow:
                  '<div class="slick-arrow-prev">' +
                  '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-left text-white fa-4xs"></i></a>' +
                  '</div>',
              adaptiveHeight: true,
              infinite: false,
              responsive: [
                {
                  breakpoint: 1300,
                  settings: {
                      slidesToShow: 1,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                  },
                }
              ],
          },
        };
    },
    methods: {
        isVimeoVideo(video,type) {
          if(type === 'studio' || type === 'gym') return true;
          return (video.url && video.url.includes('vimeo'))||(video.embed_video && video.embed_video.includes('vimeo'));
        },
        seeMore() {
          switch (this.type.toLowerCase()) {
            case 'featured-event':
              this.$router.push("/wellbeing-studio");
              break;
            case 'studio':
              this.$router.push("/wellbeing-studio");
              break;
            case 'recipes':
              this.$router.push("/personal/nutrition");
              break;
            case 'gym':
              this.$router.push("/digital-gym");
              break;
            case 'classes':
              this.$router.push("/digital-gym");
              break;
            case 'exercise':
              this.$router.push("/digital-gym?selectedView='training_plans'");
              break;
            case 'courses':
              this.$router.push("/wellbeing");
              break;
            case 'surge':
              this.$router.push("/wellbeing");
              break;
            case 'be_calm':
              this.$router.push("/be-calm");
              break;
            case 'clinic':
              this.$router.push("/clinics/digital");
              break;
            case 'podcasts':
              this.$router.push("/sound-space/");
              break;
          }
        },
        handleBeforeChange() {
          //prevents reroute whilst tiles are in motion
          this.swiping = true;
          setTimeout(()=> {this.swiping = false},1000)
        },
        async handleSwipe(event, slick, currentSlide) {
            //if the total records - the current slide is 4x
            if (this.content.length - currentSlide === 4 && this.page <= this.totalPages) {
                this.loading_new_content = true;
                await this.getContent();
            }
        },
        async getContent(reInit = false, restart = false) {
          this.loading_new_content = true;
          let contentPath = '';
          switch(this.type) {
            case 'gym':
              contentPath = "/application/api/u/studio/events";
              break;
            case 'studio':
              contentPath = "/application/api/u/studio/events";
              break;
            case 'featured-event':
              contentPath = "/application/api/u/studio/events/trending";
              break;
            case 'recipes':
              contentPath = "/application/api/u/content/nutrition";
              break;
            case 'clinic':
              contentPath = "/application/api/u/home/get-dc-banner";
              break;
            default:
              contentPath = "/application/api/u/content";
          }
          if(restart) this.page = 1;
          const payload = {
              user_id: this.$store.getters.user_id,
              tags: this.optionalTag.concat([this.type === 'featured-event' ? 'studio' : this.type]),
              page: this.page,
              pageSize: 6,
          };
          if(this.permaTag) {
            payload.tags.push(this.permaTag)
          }
          if(this.upcoming) {
            payload.upcoming = true;
          }
          let res = await this.axios.post(
              process.env.VUE_APP_API_PATH +
              contentPath,
              payload, config.options
          );
          if (res.data.success) {
              if(this.type === 'clinic') {
                  this.content = res.data.categories;
                  this.totalRecords = res.data.categories.length;
                  this.totalPages = 0;
                  this.clinicSettings = res.data.dc_setting;
                  this.loaded = true;
              } else if (this.page === 1) {
                  this.content = res.data.data;
                  this.totalRecords = res.data.meta.pagination.total;
                  this.totalPages = res.data.meta.pagination.total_pages;
                  this.page++;
                  if (reInit) this.reinitSlider(true);
                  this.loaded = true;
              } else {

                  this.loading_new_content = false;
                  this.content = this.content.concat(res.data.data);
                  this.page++;
                  this.reinitSlider();
                  this.loaded = true;
              }
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            } else {
              console.log('no data')
              this.loading_new_content = false;
              this.loaded = true;
          }

        },
        reinitSlider(backToStart = false) {
            let self = this;
            if(backToStart) this.slickOptions.initialSlide = 0;
            if (self.$refs.slick) {
                let currentSlide = backToStart ? 0 : self.$refs.slick.currentSlide();
                this.$refs.slick.destroy();
                this.$nextTick(() => {
                    let options = this.type === 'featured-event' ? self.eventSlickOptions : self.slickOptions;
                    options.initialSlide = currentSlide;
                    self.$refs.slick.create(options);
                });
            }
            this.loading_new_content = false;
        },

    },
    mounted() {
        let self = this;
        EventBus.$on("setTag", function (tag) {
            if (tag) {
                self.optionalTag = [tag.tag];
            } else {
                self.optionalTag = [];
            }
        });
        this.getContent(true);
        EventBus.$on('setBookingReminders', (data) => {
          this.reminders = data;
        })
    },
    watch: {
        optionalTag() {
            if (this.type !== 'featured-event') {
                this.loaded = false;
                if(this.$refs.slick){
                    this.$refs.slick.goTo(0);
                }
                this.page = 1;
                this.getContent(true,true);
            }
        },
    },
};
</script>

<style lang="less">
.HomeCardCarousel {
    &.hide-arrows {
      .std-arrow {
        display: none !important;
      }
    }
    .home-title-line {
        height: 1px;
        width: 200px;
        max-width: 50%;
    }
    .hideLoader {
      width: 0px !important;
    }
    .slick-slide:has(.hideLoader) {
      width: 0px !important;
    }
    .see-more-link {
      font-size: 1.5em;
      &:hover {
        cursor: pointer;
      }
    }
    .slick-slider {
        .slick-slide {
            transform: scale(1);
            margin: 0 8px;
        }
    }

    .slick-list {
        margin: 0 -15px;
        overflow: visible;
    }

    .slick-arrow-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        padding: 25px;
        height: 100%;
        display: none;
    }
    @media only screen and (max-width: 767px) {
      .home-title-line {
        display: none !important;
      }
    }
    @media only screen and (min-width: 980px) and (max-width: 992px) {
        .slick-arrow-container {
            display: flex;
        }
    }

    @media only screen and (min-width: 1130px) and (max-width: 1200px) {
        .slick-arrow-container {
            display: flex;
        }
    }

    @media only screen and (min-width: 1320px) {
        .slick-arrow-container {
            display: flex;
        }
    }
    .displayLoader {

    }
    .slick-arrow-next {
        right: -45px;
    }

    .slick-arrow-prev {
        left: -75px;
    }
    .std-arrow {
      top: 70px !important;
      &.slick-arrow-next {
        right: 0px;
      }
      &.slick-disabled {
        display: none !important;
      }
      &.slick-arrow-prev {
        left: -25px;
      }
      .std-arrow-container {
        background-color: rgba(245, 241, 241, 0.7);
      }
    }
    .slick-track{
        margin-left: 0;
    }

    .slick-slide.slick-active {
        pointer-events: auto;
        opacity: 1;
    }

    .slick-slide {
        pointer-events: none;
    }

    .slick-disabled {
        .bg-brand-highlight-1 {
            background-color: #eee;
            cursor: default;
        }
    }
    .video-blur-container {
        pointer-events: none;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        .container {
            flex-grow: 1;

        }

        .left-blur {
            flex-grow: 1;
            background: rgb(247, 247, 247);
            background: linear-gradient(270deg, rgba(255, 255, 255, 0.0) 5%, rgba(255, 255, 255, 0.7) 15%, rgba(255, 255, 255, 1) 45%);
        }

        .right-blur {
            flex-grow: 1;
            background: rgb(247, 247, 247);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 5%, rgba(255, 255, 255, 0.7) 15%, rgba(255, 255, 255, 1) 45%);
        }
    }
}
</style>
