<template>
  <div id="Navigation" class="nav-container d-flex flex-nowrap bg-brand-nav" v-if="$store.getters.skin !== 29" ref="nav">
    <div class="bg-white logo-col">
      <img @click="$store.getters.user.user_client_id !== 3519 ? $router.push('/') : $router.push('/personal/my-company')"
        class="nav-logo clickable img-fluid" :src="getLogo" onerror="javascript:this.src='/img/logo-spectrum-life.svg'" />
    </div>
    <div class="triangle-div border-brand-triangle">
      <div class="triangle-div-overlap"></div>
    </div>
    <div class="nav-col d-flex justify-content-end">
      <b-navbar class="d-flex flex-row flex-wrap align-items-center h-100">
        <b-navbar-nav class="ml-auto flex-wrap justify-content-end">
          <div v-for="(item, i) in nav" :key="i">
            <b-nav-item @click="navigate(item)" v-if="!item.hasOwnProperty('sub_items')">
              {{ item.label }}
            </b-nav-item>
            <b-nav-item-dropdown :text="item.label" v-else>
              <b-dropdown-item class="brand-text" @click="navigate(subItem)" v-for="(subItem, i) in item.sub_items"
                :key="i">
                <div class="brand-text dropdown-item-inner d-flex flex-row align-items-center">
                  <i class="pr-3 fa-solid brand-highlight-1" :class="subItem.icon" v-if="subItem.icon !== null"></i>
                  <span>{{ subItem.label }}</span>
                  <i class="fas fa-arrow-right pl-3 nav-arrow"></i>
                </div>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>
      </b-navbar>
      <div class="dark-mode-container" v-if="false">
          <div>Dark Mode</div>
          <div class="switch-holder">
              <div class="switch" :class="darkMode ? 'toggled': 'untoggled'" @click="toggleDarkMode">
                  <div class="switch-text">{{darkMode ? 'ON' : 'OFF'}}</div>
              </div>
          </div>
      </div>

    </div>
  </div>
  <div id="Navigation" class="bg-white" v-else>
    <div class="content-container d-flex flex-row flex-nowrap justify-content-between align-items-center w-100 py-2">
        <img @click="$router.push('/gp')" class="bupa-nav-logo clickable ml-2 ml-sm-0" src="/img/gp/bupa_logo.svg"
          onerror="javascript:this.src='/img/logo-spectrum-life.svg'" />
        <b-button variant="cta-outline" class="py-4 mr-2 mr-sm-0" squared size="lg" :href="getBupaTouchLink">Go back to Bupa Touch</b-button>
    </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Navigation",
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    toggleDarkMode() {
      this.$store.dispatch("setDarkMode",!this.darkMode)
    },
    navigate(item) {
      if (!item.external_link) {
        if (item.asset_id) {
          this.$router.push({ name: item.item_slug, params: { id: item.asset_id } });
        } else {
          this.$router.push({ name: item.item_slug });
        }
      } else {
        window.open("https://" + item.external_link, "_blank");
      }
    },
  },
  computed: {
    ...mapGetters(["hardcoded", "skin", "client", "nav", "darkMode","skinsWithDarkMode"]),
    darkModeAvailable() {
      return this.skinsWithDarkMode.includes(this.skin)
    },
    currentPage() {
      return this.$route.name;
    },
    getBupaTouchLink() {
      if(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging'){
        return 'https://scsmoke3.np.bupa.co.uk/touchdashboard/dashboard/gp-options'
      }else{
        return 'https://www.bupa.co.uk/touchdashboard/dashboard/gp-options'
      }

    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.client_logo) {
        return this.hardcoded.client_logo;
      } else if (this.skin === 8) {
        return "/img/boi/boi-logo-alt.png";
      } else return this.client.client_logo;
    },
  },
};
</script>
<style lang="less" >
#Navigation {
  height: 80px;
  max-height: 80px;

  .logo-col {
    display: flex;
    flex-grow: 1;
    max-width: 50%;
    padding-left: calc((100% - 1170px) / 2);

    .nav-logo {
      height: 100%;
      padding: 5px;
    }
  }

  .nav-container {
    max-width: 100%;
    margin: 0;
    border-bottom: 8px solid #e4e4e7;
  }

  .nav-col {
    flex-grow: 1;
    flex-shrink: 1;
    //padding-right: calc((100% - 1170px) / 2);
    .nav-link {
      font-size: 1.6rem;
      padding-right: 25px;
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-size: 1.6rem;

      .dropdown-item-inner {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-grow: 1;
        align-items: center;
      }

      .nav-arrow {
        opacity: 0;
      }

      &:hover {
        font-weight: bold;

        .nav-arrow {
          opacity: 1 !important;
        }
      }

      &:active {
        background-color: #dedede;
      }
    }

    ul.dropdown-menu {
      z-index: 10000;
      border-radius: 16px;
      padding: 0;

      overflow: hidden;
    }
  }

  .triangle-div {
    flex-shrink: 1;
    width: 80px !important;
    max-height: 80px !important;
    padding-left: 10px;
    border-style: solid;
    border-width: 80px 80px 0 0;
  }

  .triangle-div-overlap {
    position: relative;
    top: -80px;
    right: 10px;
    width: 80px;
    border-style: solid;
    border-width: 80px 80px 0 0;
    border-color: white transparent transparent transparent;
  }
  .dark-mode-container {
    color: white;
    width: 125px;
    padding-right: calc(((100% - 1170px) / 2) - 125px);
    font-family: 'DMSans', sans-serif;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center
  }
  .switch-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    .switch {
      width: 50px;
      height: 19px;
      border-radius: 20px;
      cursor: pointer;
      background-color: #56554D;
      position: relative;
      .switch-text {
        color: white;
        width: 100%;
        height: 100%;
        line-height: 18px;
        font-size: 0.85rem;

      }
      &::after {
        content: '';
        width: 15px;
        height: 15px;
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        border-radius: 50%;
        position: absolute;
        box-shadow: 1px 1px 1px #6A356A;
        top: 2px;
        left: 3px;
        transition: transform 0.25s ease-out;
      }
      &.untoggled {
        background-color: #D3D3D3;
        .switch-text {
          text-align: right !important;
          padding-right: 5px;
          transform: translate(0px, 0px);
        }
      }
      &.toggled {
        .switch-text {
          text-align: left !important;
          padding-left: 8px;
        }
        &::after {
          top: 2px;
          transform: translate(30px, 0px) rotate(180deg);
        }
      }
    }
  }
  .bupa-nav-logo{
    max-height: 70px;
  }
}
</style>
