<template>
  <div
    id="course"
    :class="{
      'bg-transparent': skin === 0 && activeModule.module_type !== 'check',
      'bg-white': skin !== 0 || activeModule.module_type === 'check',
    }"
  >
    <div class="content-container row">
      <div
        class="col-sm-12 col-lg-4 px-0 course-chapters d-flex align-items-stretch"
        v-if="menuDisplay || window.width > 992"
      >
        <div class="chapters__section d-flex flex-column" ref="chapters">
          <div class="chapters__title bg-brand-primary">
            <h3 class="chapters__title-text" id="chapter">
              {{ componentText.chaptersHeading }}
            </h3>
            <div class="section__header__divider"></div>
          </div>
          <div class="chapters__wrapper chapter-scroll--hide bg-brand-primary">
            <div
              class="chapters__content scrolling-chapter-content bg-brand-primary"
              id="module-slide-in-out"
              ref="chapterElement"
            >
              <div
                class="chapter"
                v-for="(module, index) in path.modules"
                :class="{
                  'chapter-active': module.is_active,
                  'completed-inactive-mod':
                    module.complete &&
                    !module.is_active &&
                    index < module_index,
                }"
              >
                <div
                  href="#"
                  class="chapter__link"
                  :id="'chapter__link-' + index"
                >
                  <img
                    v-if="module.complete"
                    v-bind:src="
                      '/img/chapter-' +
                      module.module_type +
                      getPathsChapterTypeImage() +
                      '.svg'
                    "
                    class="chapter__logo"
                  />
                  <img
                    v-if="!module.complete"
                    v-bind:src="
                      '/img/chapter-' + module.module_type + '-white.svg'
                    "
                    class="chapter__logo"
                  />

                  <div
                    class="chapter__title"
                    @click="changeChapters(index, true)"
                  >
                    <h5 class="chapter__title-header">
                      {{ componentText.chapter }} {{ index + 1 }}:
                    </h5>
                    <a class="chapter__title-text">{{ module.title }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="chapters__bottom-title bg-brand-primary"
            ref="chapterFooterElement"
          >
            <img :src="'/img/eye' + getPathsChapterTypeImage() + '.svg'" />
            <span
              >{{ componentText.reviewed }} {{ author.name }},
              {{ author.title }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="col-12 col-lg-8 px-0 path-right"
        ref="rightPath"
        v-if="!menuDisplay || window.width > 992"
      >
        <div class="chapters__main">
          <div
            class="chapters-heading row m-0 pt-5 align-items-center justify-content-between"
            v-if="activeModule.module_type !== 'check'"
          >
            <div class="px-0 pb-3 col-12">
              <a
                class="chapters__back-link brand-secondary pb-5"
                @click="$router.push({ name: 'wellbeing' })"
              >
                <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                {{ componentText.back }}
              </a>
            </div>
            <div class="col-12 col-lg-6 p-0 flex-column">
              <div class="chapters-heading__text brand-secondary">
                {{ componentText.explore }}
              </div>
              <div
                class="chapters-heading__text chapters-heading__text_lg brand-secondary"
              >
                {{ course_title }}
              </div>
            </div>
            <div
              class="col-12 col-lg-6 px-0 py-5"
              v-if="!$store.state.accessCode"
            >
              <div class="progress">
                <div
                  class="progress-bar"
                  :style="'width: ' + course_progress + '%'"
                >
                  <div class="progress-info">{{ course_progress }} %</div>
                </div>
              </div>
            </div>
          </div>
          <a
            v-if="window.width < 992"
            class="m-0 chapters__back-link brand-secondary pt-5"
            @click="hideMobileChapterMenu"
          >
            <font-awesome-icon icon="chevron-left"></font-awesome-icon>
            {{ componentText.chapters }}
          </a>
          <div
            class="module-content"
            v-if="activeModule"
            :style="{ height: contentHeight - 20 + 'px' }"
          >
            <div
              class="col-md-12 mod-video"
              v-if="activeModule.module_type === 'video'"
            >
              <GAVideoPlayer v-if="!activeModule.embed_code" class="'w-100 video'" :src="assetUrl + '#t=2'"/>
              <div v-else v-html="decodedEmbed"></div>
            </div>
            <div
              class="col-md-12 module-image"
              v-if="activeModule.module_type === 'image'"
            >
              <img :src="assetUrl" />
            </div>
            <div v-if="activeModule.module_type === 'text'">
              <CourseText
                :content="activeModule.content"
                :key="activeModule.module_id"
              ></CourseText>
            </div>
            <div
              class="col-lg-12 module-audio"
              v-if="activeModule.module_type === 'audio'"
            >
              <audio
                controls
                :key="activeModule.module_id"
                class="audio-player"
                @ended="getNextMethod(module_index)"
                :autoplay="$store.getters.skin === 10"
              >
                <source :src="assetUrl" type="audio/mpeg" />
              </audio>
            </div>
            <div v-if="activeModule.module_type === 'check'">
              <KnowledgeCheck
                v-on:next="changeChapters(module_index + 1, false)"
                :module="activeModule"
                :key="activeModule.module_id"
              ></KnowledgeCheck>
            </div>
          </div>
        </div>
        <div
          v-if="path != '' && activeModule.module_type !== 'check'"
          class="chapters__main nav-buttons py-5"
        >
          <b-button
            v-show="module_index > 0"
            class="cta-button px-5 py-3"
            @click="changeChapters(module_index - 1)"
          >
            {{ componentText.previous }}
          </b-button>
          <!-- Check if the current module is the final module -->
          <b-button
            @click="finishModule"
            class="cta-button px-5 py-3"
            v-if="path.modules.length === module_index + 1 || showFinish"
            >{{ componentText.finish }}</b-button
          >
          <b-button
            class="cta-button px-5 py-3"
            @click="changeChapters(module_index + 1, false)"
            v-else
          >
            {{ componentText.next }}
          </b-button>
        </div>
        <div class="completed-chapter__modal">
          <!-- Modal -->
          <b-modal id="completedChapterModal">
            <div class="modal-dialog modal-dialog-centered row" role="dialog">
              <div class="completed-chapter__text col-12">
                {{ componentText.completed }} {{ course_title }}
                {{ componentText.completed2 }}
              </div>
              <div class="col-12 text-align--center">
                <button class="btn btn-purple">
                  <a href="/wellbeing">{{ componentText.backToAll }}</a>
                </button>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
    <popup
      v-if="showPopup"
      :content="popupContent"
      v-on:back="allPathsNav"
      v-on:close="allPathsNav"
      v-on:rating="createReview"
    ></popup>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import AudioPlayer from "../components/AudioPlayer.vue";
import Popup from "../components/Popup.vue";
import CourseText from "../components/Wellbeing/CourseText.vue";
import KnowledgeCheck from "../components/Wellbeing/KnowledgeCheck.vue";
import GAVideoPlayer from "../components/GAVideoPlayer.vue";
export default {
  name: "Course",
  components: {
    GAVideoPlayer,
    Popup,
    AudioPlayer,
    CourseText,
    KnowledgeCheck,
  },
  data() {
    return {
      search: "",
      path: {
        modules: {},
      },
      course_progress: "",
      course_title: "",
      author: "",
      activeModule: {},
      assetUrl: "",
      module_index: "",
      contentHeight: "",
      course_id: "",
      act_mod: "",
      img_type: {
        video: "fas fa-video",
        text: "fas fa-edit",
        image: "far fa-image",
        pdf: "",
        audio: "fas fa-podcast",
      },
      page_scroll_start: true,
      page_scroll_end: false,
      mod_breakpoints: [],
      mod_index: "",
      scrollTo: true,
      path_rating: 0,

      isShowChaptersMenu: true,
      end_modal_active: false,
      skin: this.$store.getters.skin,
      popupContent: {},
      showPopup: false,
      menuDisplay: true,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getCourse();
  },
  computed: {
    decodedEmbed() {
      return this.activeModule.embed_code ? atob(this.activeModule.embed_code) : null;
    },
    showFinish: function () {
      let len = this.path.modules.length - 1;
      if (len === this.mod_index) {
        return true;
      } else {
        return false;
      }
    },
    componentText() {
      return this.getText.loggedIn.wellbeing.course;
    },
  },
  watch: {
    mod_index() {
      let mod = this.path.modules;
      let index = this.mod_index;
      let module = mod[index];
    },
    activeModule(module) {
      this.assetUrl = module.asset_url;
    },
  },
  methods: {
    getNextMethod(module_index) {
      if (this.path.modules.length === module_index + 1 || this.showFinish) {
        this.finishModule();
      } else {
        this.changeChapters(module_index + 1, false);
      }
    },

    async getCourse() {
      const payload = {
        user_id: this.$store.getters.user_id,
        course_id: this.$route.params.id,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/get-course",
        payload,
        config.options
      );
      if (res.data.success) {
        this.path = res.data;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
      this.course_progress = this.path.progress;
      this.course_title = this.path.course.course_title;
      this.author = this.path.author;
      this.setInitialActiveModule();
    },
    setInitialActiveModule() {
      if (this.$store.state.accessCode) {
        this.activeModule = this.path.modules[0];
        this.path.modules[0].is_active = true;
        this.module_index = parseInt(0);
      } else {
        for (let module in this.path.modules) {
          if (this.path.modules[module].complete === 0) {
            this.activeModule = this.path.modules[module];
            this.path.modules[module].is_active = true;
            this.module_index = parseInt(module);
            return;
          }
        }
        const last = this.path.modules.length - 1;
        this.activeModule = this.path.modules[last];
        this.path.modules[last].is_active = true;
        this.module_index = last;
      }
    },
    changeChapters(newIndex, showMenu) {
      if (!this.activeModule.complete) {
        this.path.modules[this.module_index].complete = true;
        this.completeCourseModule(this.activeModule.module_id);
      }
      if (this.path.modules[newIndex]) {
        this.activeModule.is_active = false;
        this.module_index = newIndex;
        this.activeModule = this.path.modules[newIndex];
        this.activeModule.is_active = true;
        const scrollTo = document.getElementById("course");
        scrollTo.scrollIntoView({ behavior: "smooth" });
      }
      if (this.window.width < 992 && showMenu) {
        this.hideMobileChapterMenu();
      }
    },
    hideMobileChapterMenu() {
      this.menuDisplay = !this.menuDisplay;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    async completeCourseModule(id) {
      const payload = {
        user_id: this.$store.getters.user_id,
        module_id: id,
      };
      try {
        let res = await axios.post(
          config.api_env + "/application/api-hc/complete-course-module",
          payload,
          config.options
        );
        this.course_progress = res.data.course_progress;
      } catch (e) {}
    },
    getPathsChapterTypeImage() {
      if (this.skin > 0) {
        if (this.skin < 7) {
          return "-generic";
        }
        return "-white";
      }
      return "";
    },
    finishModule() {
      this.completeCourseModule(this.activeModule.module_id);
      this.popupContent = {
        header: this.componentText.completed + " " + this.course_title + "",
        content: "",
        buttonText: this.componentText.backToAll,
        showRating: true,
      };
      this.showPopup = true;
    },
    hidePopup() {
      this.showPopup = false;
    },
    allPathsNav() {
      this.$router.push("/wellbeing").catch((err) => {});
    },
    async createReview(rating) {
      const payload = {
        user_id: this.$store.getters.user_id,
        content_type: "course",
        content_id: this.$route.params.id,
        rating: rating,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/create-review",
        payload,
        config.options
      );
      if (res.data.success) {
        this.allPathsNav();
      }
    },
  },
};
</script>
<style lang="less">
//TODO:: Thoroughly Tidy this css
#course {
  .course-chapters {
    min-height: 100vh;
    height: 100%;
    position: sticky;
    top: 0;
    .chapters__section {
      width: calc(100% - 55px);
    }
  }
  .chapters__section:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    width: 1000px;
    z-index: 0;
  }

  .chapters {
    padding-top: 20px;
    position: relative;
  }

  .chapters__title {
    display: flex;
    align-items: center;
    padding: 20px 0 10px 20px;
    position: relative;
    height: 84px;
    width: calc(100% - 20px);
    z-index: 2;
  }

  .chapters__title::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    display: inline-block;
    width: 80px;
    height: 100%;
    transform: skew(40deg) translateX(50%);
    background-color: #fff;
    z-index: 1;
  }

  .chapters__title-text {
    font-size: 23px;
    font-family: "FilsonProBold", sans-serif;
    color: white;
    margin-right: 20px;
  }

  .chapters__wrapper {
    background-color: #87d2dd;
    width: calc(100% + 55px);
  }

  .chapters__content {
    padding: 20px 0 20px 20px;
    position: relative;
  }

  .chapter {
    position: relative;
    padding: 10px 0;
  }

  .chapter:first-child::before,
  .chapter:last-child::before {
    position: absolute;
    content: "";
    display: block;
    left: 62px;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: #fff;
  }

  .chapter:first-child::before {
    top: -10px;
  }

  .chapter:last-child::before {
    bottom: -15px;
  }
  .chapter__link::after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 64px;
    width: 2px;
    height: 110%;
    background-color: #fff;
  }
  .chapter:first-child .chapter__link::after {
    top: -10px;
    height: 120%;
  }

  .chapter:last-child .chapter__link::after {
    bottom: -10px;
    height: 120%;
  }

  .module-content {
    padding-top: 50px;
    height: 100%;
    .video {
      border-radius: 20px;
      &:focus {
        outline: none;
      }
    }
  }

  .chapters__main {
    padding-left: 80px;
    padding-right: 58px;
  }

  .chapters__main .mod-video {
    margin-top: 40px;
  }

  .view__chapters-link,
  .chapters__back-link {
    display: inline-block;
    position: relative;
    padding-top: 4px;
    font-family: DMSans, sans-serif;
    font-size: 16px;
    text-decoration: none;
    line-height: 14px;
  }

  .view__chapters-link:hover,
  .chapters__back-link:hover {
    color: #87d2dd;
  }
  .view__chapters-link img,
  .chapters__back-link img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 15px;
  }

  .chapters-heading {
    //position: sticky;
    top: 0;
    padding-top: 24px;
    z-index: 10;
  }

  .chapters-heading__text {
    font-family: FilsonProLight, sans-serif;
    font-size: 21px;
    line-height: 1;
    text-transform: uppercase;
  }

  .chapters-heading__text_lg {
    margin-top: 4px;
    font-family: FilsonProBold, sans-serif;
    font-size: 33px;
    text-transform: unset;
    padding-right: 20px;
  }

  .chapters-progress {
    position: relative;
    height: 16px;
  }

  .chapters-progress__bar {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 6px;
    background-color: #ced1d2;
    border-radius: 10px;
    transform: translateY(-50%);
    z-index: 0;
  }

  .chapters-progress__fill {
    position: relative;
    height: 100%;
    width: 60%;
    background-color: #9794c8;
    border-radius: 6px;
    z-index: 1;
    transition: linear 0.4s;
  }

  .chapters-progress__value {
    position: absolute;
    bottom: calc(100% + 22px);
    right: 0;
    padding: 8px 11px 5px;
    font-family: "FilsonProBold", sans-serif;
    font-size: 16px;
    line-height: 1;
    color: white;
    border-radius: 6px;
    background-color: #9794c8;
    transform: translateX(calc(50% - 8px));
  }

  .chapters-progress__value:after {
    position: absolute;
    content: "";
    top: 100%;
    left: 50%;
    transform: translate(-50%, -2px);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #9794c8;
  }

  .mod-video {
    padding: 0 50px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .mod-video video {
    display: block;
    margin: 0 auto;
  }

  .chapters-audio {
    padding-top: 98px;
  }

  .chapters-audio__play {
    width: 44px;
    height: 48px;
  }

  .chapters-audio__track {
    height: auto;
    width: 100%;
    padding-left: 20px;
  }

  .chapters-audio__play img,
  .chapters-audio__track img {
    width: 100%;
    height: 100%;
  }

  .chapters-audio__play img {
    width: 100%;
    height: 100%;
  }

  .chapter .chapter__link {
    display: flex;
    align-items: center;
    letter-spacing: 0.1rem;
    text-decoration: none;
  }

  .chapter.completed-inactive-mod .chapter__link {
    color: #757475;
  }
  .chapter.completed-inactive-mod::after,
  .chapter.completed-inactive-mod .chapter__sign {
    background-color: #757475;
  }

  .chapter.completed-inactive-mod::after {
    opacity: 0.5;
  }

  .chapters-text {
    padding: 10px 0;
    max-width: 100%;
    img {
      max-width: 100%;
    }
  }

  .chapters-text__title {
    position: relative;
    margin-top: 50px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-family: FilsonProBold, sans-serif;
    font-size: 28px;
    line-height: 1;
    color: #87d2dd;
  }

  .chapters-text__title:first-of-type:before {
    position: absolute;
    content: "";
    width: 7px;
    height: 58px;
    background: #ffffff;
    left: -34px;
    top: 50%;
    transform: translateY(-50%);
  }

  .chapters-text * {
    font-family: DMSans, sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  .chapters-text * strong {
    font-family: DMSans, sans-serif;
    font-size: 20px;
    line-height: 1;
  }

  .chapters-text * strong:first-of-type {
    margin-top: 14px;
  }

  .chapters-text * li {
    margin-bottom: 18px;
  }

  .chapters-text * li strong {
    font-size: 16px;
  }

  .chapters-text > p:first-of-type strong {
    display: inline-block;
    margin-bottom: 26px;
  }

  .chapter__logo {
    width: 36px;
    height: 40px;
  }

  .chapter__sign {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 23px;
    background-color: white;
    border: 1px solid #995583;
    z-index: 2;
  }

  .chapter__title {
    margin-left: 30px;
    padding-right: 25px;
    padding-left: 20px;
    color: white;
    position: relative;
    max-width: 280px;
  }

  .chapter:after {
    content: "";
    position: absolute;
    left: 58px;
    top: 50%;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    transform: translateY(-50%);
    background-color: #ffffff;
    border: 1px solid #fff;
    z-index: 2;
  }

  .chapter__title-header {
    font-size: 15px;
    font-family: "FilsonProBold", sans-serif;
    margin: 0;
  }

  .chapter__title-text {
    margin: 0;
    font-size: 15px;
    color: #fff;
    font-family: "DMSans", sans-serif;
    cursor: pointer;
  }

  .chapter.is-active .chapter__sign {
    background-color: #fff;
  }

  .chapter.is-active .chapter__title {
    color: #fff;
  }

  .chapter--passed {
    opacity: 0.5;
  }

  .chapter.completed-inactive-mod .chapter__logo,
  .chapter.completed-inactive-mod .chapter__title {
    opacity: 0.5;
  }

  .chapter.chapter__sign {
    background-color: #995583;
  }

  .chapter.completed-inactive-mod .chapter__title {
    color: #fff;
  }

  .chapters__bottom-title {
    font-size: 14px;
    font-family: "DMSans", sans-serif;
    color: #fff;
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    padding: 20px 10px 40px 50px;
    width: calc(100% + 55px);
  }

  .chapters__bottom-title img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    position: relative;
    bottom: -4px;
  }

  .module-image {
    margin-top: 40px;
  }

  .module-image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  .module-audio {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    .audio-player {
      border: 1px solid;
      border-radius: 50px;
      &:focus {
        outline: none;
      }
    }
  }

  .module-audio .audio-player {
    width: 80%;
  }

  .module-audio audio {
    display: block;
    margin: 0 auto;
    width: 50%;
  }

  .progress {
    position: relative;
    height: 5px;
    overflow: visible;
  }

  .progress-bar {
    position: absolute;
    height: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 3px;
    overflow: visible;
  }

  .progress-info {
    position: absolute;
    min-width: 50px;
    height: 30px;
    padding-left: 6px;
    padding-right: 6px;
    top: -45px;
    right: 0;
    transform: translateX(50%);
    font-size: 16px;
    line-height: 34px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-family: "FilsonProBold", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .progress-info:before {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  .mod-card {
    width: 100%;
    height: 100%;
    transition: all 0.5s;
  }

  .mod-card > * {
    margin-right: -20px;
  }

  .chapters__section {
    position: relative;
  }

  .chapters__section:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    width: 1000px;
    background-color: #87d2dd;
  }
  .nav-buttons {
    display: flex;
    justify-content: space-around;
    .btn {
      width: 40%;
    }
  }

  @media all and (min-width: 993px) {
    .mod-card {
      position: absolute;
      top: 10px;
      transition: all 0.5s;
      left: 0;
      height: 90%;
      width: 100%;
      z-index: 14;
      opacity: 1;
    }

    .not-active__mode.move-top,
    .not-active__mode.move-bottom {
      position: absolute;
      z-index: 0;
      opacity: 0;
      height: 0;
    }

    .not-active__mode.move-top {
      top: -1000px;
    }

    .not-active__mode.move-bottom {
      top: 10000px;
    }
  }

  @media all and (min-width: 993px) {
    .chapters .scrolling-path-content {
      overflow: hidden;
      height: 100%;
    }

    .chapters .scrolling-chapter-content {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .chapter-scroll--hide {
      overflow: hidden;
      position: relative;
    }

    .chapter-scroll--hide > * {
      margin-right: -20px;
    }

    .view__chapters-link {
      display: none;
    }
  }
  #pop-up .pop-up-content {
    h4 {
      font-size: 3rem;
      font-family: DMSans, sans-serif;
    }
  }

  @media all and (max-width: 1200px) {
    .chapter__title-text {
      font-size: 14px;
    }

    .chapters__content {
      padding: 20px 0 20px 25px;
    }

    .chapters__vertical-line {
      left: 90px;
    }
  }

  @media all and (max-width: 992px) {
    .chapters {
      padding-top: 0;
    }

    .not-active__mode {
      display: none;
    }

    .chapters .progress {
      margin: 50px 0 20px;
    }

    .chapters__bottom-title,
    .chapters__wrapper {
      width: 100%;
    }

    .chapters__title {
      width: calc(100% - 75px);
    }

    .chapters__main {
      margin-top: 10px;
      padding: 0 40px;
    }
    .articles-blog .module-audio,
    .articles-blog .mod-video {
      margin-top: 100px;
    }
    .articles-blog .mod-video video {
      width: 100%;
    }
  }

  @media all and (max-width: 767px) {
    .chapters-heading__text_lg {
      font-size: 20px;
      line-height: 22px;
    }

    .chapters-heading__text {
      font-size: 16px;
    }

    .chapters {
      min-height: calc(100vh);
    }
    .chapters__section {
      height: 100%;
    }

    .path-left {
      /*top: 200px;*/
      /*height: calc(100vh - 230px);*/
    }

    .chapters__wrapper {
      min-height: auto;
      height: calc(100%);
    }

    .path-right {
      height: 100%;
    }

    .chapters__title-text {
      display: none;
    }
    .chapters__title .section__header__divider {
      display: none;
    }

    .chapters__title {
      height: 30px;
    }

    .chapters__title::after {
      width: 125px;
    }

    .chapters__bottom-title {
      display: none;
    }

    .module-content {
      width: 100%;
      padding: 20px;
    }
    .chapters__main {
      margin-top: 10px;
      padding: 0 10px;
    }

    .chapters__content {
      padding-left: 10px;
    }

    .module-image {
      padding: 0 10px;
    }

    .mod-video,
    .module-audio {
      padding: 0;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .module-image img {
      max-width: 100%;
    }

    .module-audio audio {
      width: 100%;
    }
  }

  @media all and (max-width: 480px) {
    .chapters__section {
      /*height: calc(100% - 250px);*/
    }

    .chapters__wrapper {
      /*max-height: calc(100% - 165px);*/
      overflow-y: auto;
    }
  }

  @media all and (max-width: 375px) {
    .articles-blog .module-audio,
    .articles-blog .mod-video {
      margin-left: 15px;
      margin-right: 15px;
    }

    .mod-card__content {
      padding: 20px 0 0;
    }

    .chapters__section {
      /*height: calc(100% - 210px);*/
    }

    .chapters__wrapper {
      /*max-height: calc(100% - 165px);*/
      overflow-y: auto;
    }

    .chapters .progress {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
#completedChapterModal {
  .modal-content {
    background: transparent !important;
    border: none;
    font-family: DMSans;
    height: 300px;
    opacity: 1;
    .modal-body {
      background: transparent !important;
      border: none;
      font-family: DMSans;
      height: 300px;
      opacity: 1;
      .completed-chapter__text {
        font-size: 26px;
        text-align: center;
        margin-bottom: 30px;
      }
    }

    .modal-dialog {
      max-width: 300px;
    }

    .completed-chapter__img-wrapper {
      text-align: center;
      display: none;
    }
    .completed-chapter__img {
      width: 75%;
    }

    .progress {
      margin: 100px 0 40px;
      display: none;
    }

    .modal__btn-close {
      top: -10px;
      right: -10px;
    }

    .modal__btn-close {
      top: -30px;
      right: -30px;
    }

    .completed-chapter__rating {
      margin: 45px 0 25px 0;
    }

    .vue-star-rating {
      justify-content: center;
    }

    .progress-info {
      right: 50%;
    }

    @media all and (max-width: 575px) {
      .completed-chapter__img-wrapper {
        margin-bottom: 0;
        padding: 25px 0;
      }
    }

    @media all and (max-width: 375px) {
      .progress {
        width: 85%;
        margin: 35px auto;
      }

      .completed-chapter__img {
        width: 25%;
      }

      .progress-bar {
        height: 8px;
      }

      .completed-chapter__text {
        font-size: 25px;
        margin-bottom: 20px;
      }

      .text-align--center > img {
        max-width: 60%;
      }

      .insights__modal-text {
        font-size: 18px;
        line-height: 22px;
      }

      .insights__modal-text > p {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
